<div class="c row fill mt-3">
  <div class="search-wrapper">
    <soft-input #input (valueChange)="searchText$.next(input?.value ?? '')" [placeholder]="'#WORKSHOP.TASK_OUTLINE.SEARCH.PLACEHOLDER' | translate"></soft-input>
    <i class="fas fa-search color-secondary"></i>
  </div>
</div>

<section class="mt-4 pb-3 mb-3">
  <div class="card-stack mt-4 mb-3" *ngIf="(loading$ | async) === false">
    <ng-container *ngFor="let auftrag of filteredAuftraege$ | async">
      <soft-auftrag-card [auftrag]="auftrag" (click)="navigateTo(auftrag)"></soft-auftrag-card>
    </ng-container>
  </div>
  <div class="loading h-screen flex items-center justify-center text-right" *ngIf="loading$ | async">
    <soft-loading-spinner class="spin"></soft-loading-spinner>
  </div>
</section>

<div *ngIf="(loaded$ | async) && (filteredAuftraege$ | async)?.length === 0"
     class="c row center center-v center-h height-100 color-light">
  {{ '#WORKSHOP.COMPONENTS.OVERVIEW.NO_DATA' | translate }}
</div>
