import { InjectionToken } from '@angular/core';
import {ResourceService} from './data/abstraction';

export const SOFTLINE_CONFIG_COOKIE_PREFIX = new InjectionToken<string>(
  'CookiePrefix'
);
export const SOFTLINE_SERVICE_UUID = new InjectionToken<() => string>(
  'SOFTLINE_SERVICE_UUID'
);
export const SOFTLINE_SERVICE_HTTP = new InjectionToken<ResourceService<any>>(
  'SOFTLINE_SERVICE_HTTP'
);
