import { Directive, forwardRef, Input } from '@angular/core';
import { SelectOptionBase } from './select-option-base';

@Directive({
  selector: 'soft-select-option-separator',
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionSeparatorDirective),
    },
  ],
})
export class SelectOptionSeparatorDirective extends SelectOptionBase {
  @Input() id?: string;

  constructor() {
    super();
  }
}
