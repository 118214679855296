import { InjectionToken, Injector, Type } from "@angular/core";
import { FieldOkService } from './services/field-ok.service';
import { Rule } from "./data/rules";
import exp from "constants";
import { Definition } from "./data/definitions";

export const SOFTLINE_FEATURE_FIELD_OK = 'fieldOk';
export const SOFTLINE_FEATURE_DEFINITIONS = 'definitions';
export const SOFTLINE_FEATURE_DYNAMIC_ACTIONS = 'dynamicActions';

export const TYPE_FIELD_OK_INPUT_COMPONENT = new InjectionToken<Type<any>>(
  'FIELD_OK_COMPONENT_TYPE'
);
export const TYPE_ENTITY_INPUT_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_ENTITY_INPUT_COMPONENT'
);
export const TYPE_LIST_INPUT_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_LIST_INPUT_COMPONENT'
);
export const TYPE_OBJECT_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_OBJECT_COMPONENT'
);
export const TYPE_LIST_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_LIST_COMPONENT'
);
export const TYPE_ACTION_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_ACTION_COMPONENT'
);
export const TYPE_ACTION_INPUT_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_ACTION_INPUT_COMPONENT'
);
export const TYPE_CONTAINER_COMPONENT = new InjectionToken<Type<any>>(
  'TYPE_CONTAINER_COMPONENT'
);

export const SOFTLINE_STORE_FIELD_OK_NAME = new InjectionToken<string>(
  'SOFTLINE_STORE_FIELD_OK_NAME'
);
export const SOFTLINE_STORE_FIELD_OK_SERVICE =
  new InjectionToken<FieldOkService>('SOFTLINE_STORE_FIELD_OK_NAME');

export interface CustomRuleResolver<T extends {name: string} = {name: string}> {
  name: string;
  resolve(rule: T, value: any): boolean;
}

export const SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER = new InjectionToken<CustomRuleResolver[]>('SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER');

export interface RegisteredDefinition {
  name: string;
  priority?: number;
  definition: Definition;
}

export const SOFTLINE_CONFIG_DEFINITION = new InjectionToken<RegisteredDefinition[]>('SOFTLINE_CONFIG_DEFINITION');
export const SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS = new InjectionToken<boolean>('SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS');
