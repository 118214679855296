import {
  ComponentFactoryResolver,
  Directive,
  Injector,
  Input,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BooleanInputComponent } from '../components/atoms/inputs/boolean/boolean-input.component';
import { DateInputComponent } from '../components/atoms/inputs/date/date-input.component';
import { DurationInputComponent } from '../components/atoms/inputs/duration/duration-input.component';
import { DynamicInputAtom } from '../components/atoms/inputs/dynamic-input-atom';
import { FileInputComponent } from '../components/atoms/inputs/file-input/file-input.component';
import { MultiSelectInputComponent } from '../components/atoms/inputs/multi-select/multi-select-input.component';
import { NumberInputComponent } from '../components/atoms/inputs/number/number-input.component';
import { ResetInputComponent } from '../components/atoms/inputs/reset/reset-input.component';
import { SelectInputComponent } from '../components/atoms/inputs/select/select-input.component';
import { SubmitInputComponent } from '../components/atoms/inputs/submit/submit-input.component';
import { StringInputComponent } from '../components/atoms/inputs/string/string-input.component';
import { Definition } from '../data/definitions';
import {
  TYPE_ACTION_INPUT_COMPONENT,
  TYPE_ENTITY_INPUT_COMPONENT,
  TYPE_FIELD_OK_INPUT_COMPONENT,
  TYPE_LIST_INPUT_COMPONENT,
} from '../dynamic.shared';
import { DrawInputComponent } from "../components/atoms/inputs/draw/draw-input.component";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[softDynamicFormField]',
})
export class DynamicInputFieldDirective implements OnInit {
  @Input() definition!: Definition;
  @Input() form!: UntypedFormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    if (!this.definition) return;
    this.createComponent(this.definition);
  }

  private createComponent(definition: Definition): void {
    const locale = 'de';

    let type: Type<DynamicInputAtom<Definition>> | undefined;
    switch (definition.type) {
      case 'action':
        type = this.injector.get(TYPE_ACTION_INPUT_COMPONENT);
        break;
      case 'string':
        type = StringInputComponent;
        break;
      case 'select':
        type = SelectInputComponent;
        break;
      case 'number':
        type = NumberInputComponent;
        break;
      case 'boolean':
        type = BooleanInputComponent;
        break;
      case 'date':
        type = DateInputComponent;
        break;
      case 'object':
        type = DateInputComponent;
        break;
      case 'draw':
        type = DrawInputComponent;
        break;
      case 'entity':
        type = this.injector.get(TYPE_ENTITY_INPUT_COMPONENT);
        break;
      case 'duration':
        type = DurationInputComponent;
        break;
      case 'fieldOk':
        type = this.injector.get(TYPE_FIELD_OK_INPUT_COMPONENT);
        break;
      case 'list':
        type = this.injector.get(TYPE_LIST_INPUT_COMPONENT);
        break;
      case 'multiSelect':
        type = MultiSelectInputComponent;
        break;
      case 'imageInput':
        type = FileInputComponent;
        break;
      case 'submit':
        type = SubmitInputComponent;
        break;
      case 'reset':
        type = ResetInputComponent;
        break;
      default:
        return;
    }

    const component = this.container.createComponent(type);

    component.instance.definition = definition;
    component.instance.form = this.form;
  }
}
