import { Pipe, PipeTransform } from '@angular/core';
import { ControlDefinition, Definition } from '../data/definitions';
import { Dictionary, isDefined } from '@softline/core';

@Pipe({
  name: 'filterDefinedValues',
  pure: true,
})
export class FilterDefinedValuesPipe implements PipeTransform {
  transform(
    definitions: Definition[],
    value: object | null | undefined
  ): Definition[] {
    if (!definitions || !value) return [];

    return definitions
      .filter((o) => (o as ControlDefinition).name)
      .filter((o) =>
        isDefined((value as Dictionary<unknown>)[(o as ControlDefinition).name])
      );
  }
}
