<div
  *ngIf="form && definition"
  class="soft-container soft-container-column"
  [formGroup]="form"
>
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-field-ok
    [formControlName]="definition.name"
    [name]="definition.fieldOk"
    [parameters]="definition.parameters || {} | resolveParameters: form.value"
    [maxAbfrageResults]="definition?.maxAbfrageResults"
    [readonly]="definition.readonly | booleanRule: form.value"
    [placeholder]="definition.placeholder"
    [resultMapping]="definition.resultMapping"
    [showQuery]="!!definition.showQuery"
    [autoLoad]="!!definition.autoLoad"
    [template]="template"
    [scan]="(definition | scanDefinition)?.scan ?? false"
    [labelTypes]="(definition | scanDefinition)?.labelTypes"
    [inputMinLength]="definition.inputMinLength ?? 0"
    [views]="definition.views"
    [title]="definition.title"
    [sort]="definition.sort"
  >
  </soft-field-ok>
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
</div>

<ng-template let-entity="entity" #template>
  <span>
    {{ definition.format | formatRule: form.value | interpolate: entity }}
  </span>
</ng-template>
