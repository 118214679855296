import {ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, inject, Provider} from '@angular/core';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS} from '@softline/application';
import {default as de} from '../i18n/de.json';
import { SOFTLINE_PERMISSION_PALETTEN_UMLAGERUNG } from "./paletten-umlagerung.shared";

export const palettenUmlagerungProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'palettenUmlagerung',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#PALETTEN_UMLAGERUNG.APP',
        description: '#PALETTEN_UMLAGERUNG.APP_DESC',
        route: '/paletten-umlagerung',
        icon: 'fas fa-pallet',
        permission: SOFTLINE_PERMISSION_PALETTEN_UMLAGERUNG
      });
    },
    multi: true
  }
];
