import {Routes} from "@angular/router";
import {AuthenticationGuard} from "@softline/auth";
import {loginRoutes} from "@softapps/allgemein/login";
import {connectionSettingsRoutes, settingsRoutes} from "@softapps/allgemein/utils";
import {passwordRoutes} from "@softapps/allgemein/password";
import {moduleRoutes} from "@softapps/allgemein/modules";
import {bestellvorschlagRoutes} from "@softapps/mde/bestellvorschlag";
import {etikettendruckRoutes} from "@softapps/mde/etikettendruck";
import {lagerInventurRoutes} from "@softapps/mde/inventur";
import {retourenRoutes} from "@softapps/mde/retouren";
import {bestellerfassungRoutes} from "@softapps/mde/bestellerfassung";
import {warenverprobungRoutes} from "@softapps/mde/warenverprobung";
import {palettenUmlagerungRoutes} from "@softapps/lager/paletten-umlagerung";
import {lagerplatzUmlagerungRoutes} from "@softapps/lager/lagerplatz-umlagerung";
import {auftragRoutes} from "@softapps/werkstatt/auftrag";
import {lagerkorrekturRoutes} from "@softapps/lager/lagerkorrektur";
import {inventurRoutes} from "@softapps/lager/inventur";
import {kommissionierungRoutes} from "@softapps/lager/kommissionierung";
import {lagerstandRoutes} from "@softapps/lager/lagerstand";
import {lagerkorrekturArtikelRoutes} from "@softapps/lager/lagerkorrektur-artikel";
import {artikelInfoRoutes} from "@softapps/wws/artikel-info";

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.ShellComponent),
    children: [
      //Allgemein
      ...moduleRoutes,
      ...settingsRoutes,

      // MDE
      ...bestellvorschlagRoutes,
      ...etikettendruckRoutes,
      ...lagerInventurRoutes,
      ...retourenRoutes,
      ...bestellerfassungRoutes,
      ...warenverprobungRoutes,
      ...artikelInfoRoutes,

      // Werkstatt
      ...auftragRoutes,

      //Lager
      ...palettenUmlagerungRoutes,
      ...lagerplatzUmlagerungRoutes,
      ...lagerkorrekturRoutes,
      ...inventurRoutes,
      ...kommissionierungRoutes,
      ...lagerstandRoutes,
      ...lagerkorrekturArtikelRoutes
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.PublicShellComponent),
    children: [
      ...loginRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes,
    ],
  },
];
