import {EntityListDataView} from '@softline/dynamic';

export const activityListViewDefinition: EntityListDataView = {
  type: 'list',
  title: 'Tätigkeiten',
  virtual: {itemHeight: 54, display: {default: 8, small: 4}},
  definition: {
    type: 'object',
    name: '',
    definitions: [
      {
        type: 'string',
        class: 'pl-2 pr-2 soft-overline truncate',
        name: 'taetigkeit'
      },
      {
        type: 'string',
        class: 'pl-2 pr-2 soft-text truncate',
        name: 'bezeichnung'
      },
    ]
  }
};
