import {Command} from '@softline/application';
import {Store} from '@softline/core';
import {Auftrag} from '../data/auftrag';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {
  StartArbeitsberichtDialogComponent
} from '../dialogs/start-arbeitsbericht-dialog/start-arbeitsbericht-dialog.component';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT} from '../auftrag.shared';
import {WerkstattArbeitsberichtStore} from '../store/werkstatt-arbeitsbericht.store';
import {map, Observable} from 'rxjs';

export class StartActivityCommand implements Command {
  icon = 'fas fa-user-clock';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.START_ACTIVITY';
  class = 'menu action-menu action-menu-top';

  canExecute: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
    WerkstattArbeitsberichtStore.getters.active
  ).pipe(map(o => o === undefined || o === null));

  execute = async () => await this.startActivity();

  constructor(private store: Store, private task: () => Auftrag) {}

  async startActivity(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'START_ACTIVITY',
        component: StartArbeitsberichtDialogComponent,
        data: { auftrag: this.task() },
        dismiss: { button: true, backdrop: true }
      }
    );

    if (result !== 'DISMISSED' && this.task) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.actions.loadMany,
        { clear: false, queryParams: { orderId: this.task().id } }
      );
    }
  }
}
