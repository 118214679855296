import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'title',
})
export class TitlePipe implements PipeTransform {
  transform(value: any, options: { value: any; title: string }[]): string {
    return options.find((o) => o.value === value)?.title ?? value;
  }
}
