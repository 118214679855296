import { Definition } from './definitions';

export interface FieldOk {
  id: number;
}

export interface FieldOkParameters<T = object> {
  filter: string | null;
  multiValued: boolean;
  parameters: T;
  maxAbfrageResults?: number;
}

export interface FieldOkQueryResult<T = object> {
  list: T[];
  definition: Definition | null;
}

export interface FieldOkValidationResult<T = object> {
  value?: T;
  list?: T[];
  definition: Definition | null;
}
