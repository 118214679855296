import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RefreshService, SOFTLINE_FEATURE_MASTER_DATA } from "@softline/application";
import { RemoteObjectStore, Store } from "@softline/core";
import { map } from "rxjs/operators";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";
import { combineLatest } from "rxjs";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { Router } from "@angular/router";
import { Firma } from "../data/firma";
import { EditContextDialogComponent } from "@softapps/allgemein/utils";

@Component({
  selector: 'soft-firma-context-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './firma-context-widget.component.html',
  styleUrls: ['./firma-context-widget.component.scss']
})
export class FirmaContextWidgetComponent implements OnInit {

  firmen$ = this.store
    .observe(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.getters.data)
    .pipe(map((o) => (o as { firmaModule: Firma[] })?.firmaModule ?? []));

  activeFirmaId$ = this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data).pipe(
    map(o => (o as any)?.firmaid)
  )

  selectedFirma$ = combineLatest([this.firmen$, this.activeFirmaId$]).pipe(
    map(([firmen, currentFirmaId]) => firmen.find(({ id }) => id === currentFirmaId))
  )

  constructor(private store: Store,
              private router: Router,
              private refreshService: RefreshService) { }

  ngOnInit(): void {
  }


  async onChangeCompany(): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      component: EditContextDialogComponent,
      dismiss: {
        backdrop: true,
        escape: true,
        button: true
      }
    });
    if (result === 'DISMISSED')
      return;

    await this.refreshService.refresh()
  }

}
