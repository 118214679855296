<ng-container *ngIf="auftrag$ | async as auftrag">
  <ng-container *ngIf="strategy$ | async as strategy">
    <ng-container *ngIf="bewe$ | async as bewe">
      <div class="pb-24">
        <div class="soft-overline mt-4">
          {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.ARTIKEL' | translate}}
        </div>
        <div class="flex flex-row justify-between mb-2 hover:bg-gray-100 p-2 border border-solid border-gray-300 rounded mt-1">
          <div class="flex flex-col w-3/4">
            <span class="text-sm color-light">{{bewe.artikelMengeOffen.artikel.nummer}}</span>
            <span class="text-lg font-semibold truncate">{{bewe.artikelMengeOffen.artikel.artbez1}}</span>
            <span class="color-light truncate">{{bewe.artikelMengeOffen.artikel.artbez2}}</span>
            <span class="color-light truncate">{{bewe.artikelMengeOffen.artikel.artbez3}}</span>
            <span class="color-light truncate">{{bewe.artikelMengeOffen.artikel.artbez4}}</span>
            <span class="color-light truncate">{{bewe.artikelMengeOffen.artikel.artbez5}}</span>
            <div *ngIf="(bewe.zeilentext | isDefined) && (bewe.zeilentext !== '')"
                 class="flex flex-row mt-1 items-center">
              <i class="fas fa-info-circle"></i>
              <div class="w-full ml-2">{{bewe.zeilentext}}</div>
            </div>
          </div>
          <div class="flex flex-col justify-center items-end">
            <div class="flex flex-row flex-nowrap color-light items-center text-xl">
              <span>{{bewe.artikelMengeOffen.menge}}</span>
              <span class="ml-1">{{bewe.artikelMengeOffen.einheit.arteh}}</span>
            </div>
            <div *ngIf="bewe.artikelMengeOffen.einheit.id !== bewe.artikelMengeOffen.einheit_lg.id"
                 class="flex flex-row flex-nowrap color-light items-center text-xl">
              <span class="whitespace-nowrap">= {{bewe.artikelMengeOffen.menge_lg}}</span>
              <span class="ml-1">{{bewe.artikelMengeOffen.einheit_lg.arteh}}</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="strategy.lagerplatzAuswahl || strategy.loadLagerplatzVorschlag">
          <div class="soft-overline mt-4">
            {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.LAGERPLATZ' | translate}}
          </div>
          <ng-container *ngIf="lagerplatz$ | async as lagerplatz; else scanLagerplatz">
            <div class="soft-card border mt-1 p-6 rounded">
              <div class="flex flex-row justify-between flex-nowrap">
                <div class="flex flex-col justify-center align-middle items-center">
                  <span class="text-lg font-semibold">{{lagerplatz.lagerplatz.lagerplatz}}</span>
                </div>
                <div *ngIf="strategy.lagerplatzAuswahl"
                     class="flex flex-col justify-center align-middle items-center">
                  <button class="soft-button primary rounded-full" (click)="onScan()">
                    <i class="fas fa-barcode mr-2"></i>
                    {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.CHANGE' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #scanLagerplatz>
            <soft-scan-button></soft-scan-button>
          </ng-template>
        </ng-container>


        <div class="soft-overline mt-4">
          {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.MENGE' | translate}}
        </div>
        <soft-mengen-eingabe class="mt-1"
                             [disabled]="(disabled$ | async) ?? false"
                             [value]="(menge$ | async) ?? 0"
                             [einheit]="bewe.artikelMengeOffen.einheit.arteh"
                             [maxValue]="(maxMenge$ | async) ?? 0"
                             [float]="true"
                             (valueChange)="onMengeChange(auftrag.id, bewe.id, $event)"></soft-mengen-eingabe>

      </div>
      <button class="fixed left-0 right-0 bottom-0 soft-button primary w-full h-20 mt-16"
              [disabled]="((disabled$ | async) || (loading$ | async) || ((menge$ | async) ?? 0) <= 0) || !(lagerplatz$ | async)"
              (click)="onSubmit()">
        {{'#KOMMISSIONIERUNG.PAGES.ARTIKEL_MENGE.SUBMIT' | translate}}
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<soft-blocking-loading-spinner *ngIf="loading$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
