import * as moment from "moment/moment";

export type FilterValueType = 'string' | 'number' | 'undefined' | 'object' | 'boolean' | 'date' | 'bigint' | 'symbol' | 'function' | 'array';

export function getFilterValueTypes(values: unknown[]): FilterValueType[] {
  const types: FilterValueType[] = values
    .map(o => {
      if(typeof o === 'string' && moment(o, moment.ISO_8601, true).isValid())
        return 'date';
      if(Array.isArray(o))
        return 'array';
      return typeof o;
    })
    .filter((v, i, a) => a.indexOf(v) === i);
  return types
}
