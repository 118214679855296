import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment';
import { MomentCalendarService } from '../../../services/moment-calendar.service';
import { DateService } from '@softline/core';

export interface Day {
  date: moment.Moment;
  day: number;
}
export interface Week {
  number: number;
  days: Day[];
}
export interface Month {
  year: number;
  month: number;
  weeks: Week[];
}

@Component({
  selector: 'soft-date-picker',
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' }),
        animate(
          '150ms',
          style({ opacity: 1, clipPath: 'inset(0px 0px 0px 0%)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms',
          style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' })
        ),
      ]),
    ]),
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
  private _month = moment(this.dateService.today()).month();
  private _year = moment(this.dateService.today()).year();

  hasSelected = false;

  _value: moment.Moment = moment(this.dateService.today());
  monthView?: Month;

  get month(): number {
    return this._month;
  }
  set month(value: number) {
    const update = this._month !== value;
    this._month = value;
    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }

  get year(): number {
    return this._year;
  }
  set year(value: number) {
    const update = this._year !== value;
    this._year = value;
    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }

  @Input()
  get value(): string {
    return this._value.toISOString(true);
  }
  set value(value: string) {
    this._value = moment(value);
    const update =
      this._month !== this._value.month() || this._year !== this._value.year();
    this._month = this._value.month();
    this._year = this._value.year();

    if (update) this.monthView = this.createMonthView(this.year, this.month);
  }
  @Output() valueChange = new EventEmitter<string>();
  @Output() submit = new EventEmitter<string>();

  constructor(
    readonly dateService: DateService,
    readonly calendarService: MomentCalendarService
  ) {
    const today = moment(dateService.today());
    this._month = today.month();
    this._year = today.year();
  }

  ngOnInit(): void {
    this.hasSelected = false;

    if (!this.monthView)
      this.monthView = this.createMonthView(this.year, this.month);
  }

  private createMonthView(year: number, month: number): Month {
    const monthView: Month = { year, month, weeks: [] };
    const currentDay = moment(new Date(year, month));
    while (currentDay.weekday() > 0) currentDay.add(-1, 'day');

    while (
      (currentDay.month() <= month && currentDay.year() === year) ||
      currentDay.year() < year
    ) {
      const week: Week = { number: currentDay.isoWeek(), days: [] };
      for (let i = 0; i < 7; i++) {
        const day: Day = { date: currentDay.clone(), day: currentDay.date() };
        week.days.push(day);
        currentDay.add(1, 'day');
      }
      monthView.weeks.push(week);
    }

    return monthView;
  }

  onSelect(date: moment.Moment): void {
    this.hasSelected = true;
    this.value = date.toISOString();
    this.valueChange.emit(date.toISOString());
  }

  onSubmit(date: moment.Moment): void {
    this.onSelect(date);
    this.submit.emit(this.value);
  }
}
