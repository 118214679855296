<ng-container *ngIf="userAgentService.isMobile()">
  <div class="c center-v">
    <input
      type="date"
      class="mr-2"
      [value]="value | date: 'yyyy-MM-dd'"
      (change)="setValue(inputElement.value, true)"
      [readOnly]="readonly"
      (blur)="onTouch()"
      #inputElement
      placeholder="{{
        '#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER' | translate
      }}"
    />
  </div>
</ng-container>

<ng-container *ngIf="userAgentService.isDesktop()">
  <input
    [value]="input ?? (value | format: format || 'DATE.DEFAULT')"
    (input)="onInput(inputElement.value)"
    (change)="onBlur(inputElement.value)"
    #inputElement
    (dblclick)="presentPicker()"
    (blur)="onTouch()"
    [readOnly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER' | translate
    }}"
  />
  <div class="date-picker" *ngIf="showPicker" (dblclick)="clickedInside()">
    <soft-date-picker
      [value]="value || picker.value"
      (valueChange)="dateSelected($event)"
      (submit)="setValue($event, false); closePicker()"
      #picker
    ></soft-date-picker>
    <div class="c row end">
      <button
        type="button"
        [disabled]="!hasDateSelected"
        (click)="setValue(picker.value, false); closePicker()"
        class="soft-button soft-button-primary soft-button-small"
      >
        {{ '#UI_CORE.COMPONENTS.DATE_INPUT.OK' | translate }}
      </button>
    </div>
  </div>
  <i
    *ngIf="!readonly"
    class="fas fa-calendar-alt picker-icon pointer ml-4"
    (click)="showPicker = !showPicker; !showPicker && onTouch()"
  ></i>
</ng-container>
