import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Optional,
  Type,
} from '@angular/core';
import {
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
} from '../../../utilities.shared';
import {
  MessageBarStore,
  Modal,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import { equals, Store } from '@softline/core';
import {
  AuthenticationContextStore,
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '@softline/auth';
import {
  handleRequestErrors,
  SOFTLINE_FEATURE_SHELL,
  ShellStore,
} from '@softline/application';
import { PatchContextStore } from '../../patch-context.store';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'lib-edit-context-dialog',
  standalone: true,
  templateUrl: './edit-context-dialog.component.html',
  styleUrls: ['./edit-context-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule],
})
export class EditContextDialogComponent implements Modal<any>, OnInit {
  private close!: (result: any) => void;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    @Optional()
    @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
    public components?: Type<Component>[]
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.actions.load
    );
  }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async onSave(): Promise<void> {
    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    );

    const patchedContext = this.store.get(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data
    );

    if (equals(context, patchedContext)) {
      this.close(true);
      return;
    }

    try {
      this.loading$.next(true);
      await this.store.dispatch(

        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.change,
        patchedContext
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#UTILITIES.CONTEXT.SUCCESS'
      );
      this.close(undefined);
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
    }
  }
}
