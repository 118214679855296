<input
  [value]="
    escapeHtml
      ? (value ?? null | deserializeHtmlCharacterEntities)
      : value ?? null
  "
  (input)="setValue(inputElement.value)"
  (click)="selectText($event.target)"
  [readOnly]="readonly"
  (blur)="onTouch()"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.COMBO_BOX.PLACEHOLDER' | translate
  }}"
  #inputElement
/>

<div
  *ngIf="isOpen"
  class="combo-box-panel soft-scroll vertical"
  (softClickOutside)="onOutsideClick()"
  [delayClickOutsideInit]="true"
>
  <div
    *ngFor="let option of options"
    class="combo-box-select-option pointer"
    [class.selected]="option.value === value"
    [softScrollIntoView]="value === option.value"
    (click)="onSelect(option.value)"
  >
    {{ option.value }}
  </div>
</div>
<ng-content select=":not(soft-combo-box-option)"></ng-content>
<i
  *ngIf="!readonly && isOpen"
  (click)="isOpen = false"
  class="fas fa-chevron-up expand-icon pointer ml-4"
></i>
<i
  *ngIf="!readonly && !isOpen"
  (click)="isOpen = true"
  class="fas fa-chevron-down expand-icon pointer ml-4"
></i>
