import { Component, Input, OnInit } from '@angular/core';
import { GroupDefinition } from '../../../../data/definitions';

@Component({
  selector: 'soft-dynamic-group-value',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() definition?: GroupDefinition;
  @Input() value?: any;
  @Input() loading = false;
  @Input() displayHeader = true;

  constructor() {}

  ngOnInit(): void {}
}
