import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

export interface Mutation<T, P> {
  type: 'mutation';
  name: string;
}
export interface Getter<T, P, R> {
  type: 'getter';
  name: string;
}
export interface Action<T, P, R> {
  type: 'action';
  name: string;
}

export type StoreCommit = <T, P = undefined>(
  feature: string,
  name: Mutation<T, P>,
  params?: P
) => void;
export type StoreDispatch = <T, P = undefined, R = T>(
  feature: string,
  name: Action<T, P, R>,
  params?: P,
  injector?: Injector
) => Promise<R>;
export type StoreGet = <T, P = undefined, R = T>(
  feature: string,
  name?: Getter<T, P, R>,
  params?: P
) => Readonly<R>;
export type StoreObserve = <T, P = undefined, R = T>(
  feature: string,
  name?: Getter<T, P, R>,
  params?: P
) => Observable<Readonly<R>>;

export interface AbstractStore {
  commit: StoreCommit;
  dispatch: StoreDispatch;
  get: StoreGet;
  observe: StoreObserve;
}

export interface MutationParams<T, P> {
  state: Readonly<T>;
  params: P;
}
export interface ActionParams<T, P> {
  state: Readonly<T>;
  params: P;
  /**
   * @deprecated use fields commit / dispatch / get instead
   */
  store: AbstractStore;
  featureName: string;
  injector: Injector;
  get: StoreGet;
  commit: StoreCommit;
  dispatch: StoreDispatch;
}
export interface GetterParams<T, P> {
  state: Readonly<T>;
  params: P;
  featureName: string;
  get: StoreGet;
  injector: Injector;
}

export type MutationFunc<T, P> = (params: MutationParams<T, P>) => Readonly<T>;
export type ActionFunc<T, P, R> = (
  params: ActionParams<T, P>
) => R | Promise<R>;
export type GetterFunc<T, P, R> = (params: GetterParams<T, P>) => R;

export interface MutationReg<T, P> {
  mutation: Mutation<T, P>;
  func: MutationFunc<T, P>;
}
export interface ActionReg<T, P, R> {
  action: Action<T, P, R>;
  func: ActionFunc<T, P, R>;
}
export interface GetterReg<T, P, R> {
  getter: Getter<T, P, R>;
  func: GetterFunc<T, P, R>;
  options?: { dependingFeatures?: string[] };
}
