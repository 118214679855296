import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Dictionary, isDefined } from '@softline/core';
import { Validation } from '@softline/core';

@Pipe({
  name: 'combineValidations',
  pure: false,
})
export class CombineValidationsPipe implements PipeTransform {
  transform(formGroup: UntypedFormGroup, property?: string): Validation | null {
    let errors: Dictionary<Validation> | null;
    if (property) errors = formGroup.controls[property].errors;
    else errors = formGroup.errors;

    if (errors === null) return null;

    const validation: Validation = { isValid: true, messages: [] };
    for (const error of Object.values(errors)) {
      if (
        !isDefined(error) ||
        !isDefined(error?.isValid) ||
        !isDefined(error?.messages)
      )
        continue;
      if (error) {
        validation.isValid = validation.isValid && error.isValid;
        validation.messages.push(...error.messages);
      }
    }
    return validation;
  }
}
