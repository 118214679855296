import { Entity } from './entity';

export interface Validation {
  isValid: boolean;
  messages: ValidationMessage[];
}

export interface ValidationMessage {
  subject: string;
  message: string;
  type: 'error' | 'warning' | 'success' | 'info';
  data?: object;
}

export interface ValidationProperty<T extends Entity = Entity>
  extends Validation {
  propertyName: keyof T;
}

export interface ValidationResult<T extends Entity = Entity>
  extends Validation {
  properties: ValidationProperty<T>[];
  entity: T;
}
