import { CdkPortal } from '@angular/cdk/portal';
import {
  Component,
  ContentChild, EventEmitter,
  forwardRef,
  Input,
  OnInit, Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Tab } from './tab';
import { TabTitleDirective } from './tab-title/tab-title.directive';

@Component({
  selector: 'soft-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  providers: [
    { provide: Tab, useExisting: forwardRef(() => TabComponent), multi: true },
  ],
})
export class TabComponent extends Tab implements OnInit {
  @Input() title?: string | null | undefined;
  @ContentChild(TabTitleDirective) templateTitle?: TabTitleDirective;
  @ViewChild(CdkPortal, { static: true }) content?: CdkPortal;

  @Output() activate = new EventEmitter<any>();
  @Output() deactivate = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
