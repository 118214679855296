import { ApplicationConfig } from '@angular/core';
import {
  enableProdMode,
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
  LOCALE_ID,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import {
  ConnectionModule,
  CoreModule,
  Store,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  accountContextProviders,
  connectionSettingsProviders,
  contextSettingsProviders,
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
} from '@softapps/allgemein/utils';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule,
  ItemScanModule,
  MasterDataModule,
  OfflineModule,
  remoteConfigProviders,
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import { passwordProviders } from '@softapps/allgemein/password';
import { loginProviders } from '@softapps/allgemein/login';
import { moduleProviders } from '@softapps/allgemein/modules';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { pdfEditorProviders } from '@softapps/allgemein/pdf-editor';
import { wwsCoreProviders } from '@softapps/wws/core';
import { bestellvorschlagProviders } from '@softapps/mde/bestellvorschlag';
import { etikettendruckProviders } from '@softapps/mde/etikettendruck';
import { inventurProviders } from '@softapps/mde/inventur';
import { inventurProviders as lagerInventurProviders } from '@softapps/lager/inventur';
import { retourenProviders } from '@softapps/mde/retouren';
import { bestellerfassungProviders } from '@softapps/mde/bestellerfassung';
import { warenverprobungProviders } from '@softapps/mde/warenverprobung';
import { artikelInfoProviders } from '@softapps/wws/artikel-info';
import { auftragProviders } from '@softapps/werkstatt/auftrag';
import { lagerCoreProviders } from '@softapps/lager/core';
import { palettenUmlagerungProviders } from '@softapps/lager/paletten-umlagerung';
import { lagerplatzUmlagerungProviders } from '@softapps/lager/lagerplatz-umlagerung';
import { lagerkorrekturProviders } from '@softapps/lager/lagerkorrektur';
import { kommissionierungProviders } from '@softapps/lager/kommissionierung';
import { lagerstandProviders } from '@softapps/lager/lagerstand';
import { lagerkorrekturArtikelProviders } from '@softapps/lager/lagerkorrektur-artikel';
import { platform } from '../platforms/platform';

import { default as de } from '../../i18n/de.json';
import { default as deWerkstatt } from '../../i18n/de-werkstatt.json';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),
      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      AppearanceModule.forRoot({
        defaultSettings: { theme: environment.theme },
      }),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      OfflineModule.forRoot()
    ),

    ...remoteConfigProviders,

    //Allgemein
    ...utilitiesProviders,
    ...settingsProviders,
    ...connectionSettingsProviders,
    ...contextSettingsProviders,
    ...userContextProviders,
    ...accountContextProviders,
    ...passwordProviders,
    ...loginProviders,
    ...moduleProviders,
    ...pdfViewerProviders,
    ...pdfEditorProviders,

    // MDE Notwendig
    importProvidersFrom(ItemScanModule.forRoot()),

    // MDE
    ...wwsCoreProviders,

    ...bestellvorschlagProviders,
    ...etikettendruckProviders,
    ...inventurProviders,
    ...retourenProviders,
    ...bestellerfassungProviders,
    ...warenverprobungProviders,

    ...artikelInfoProviders,

    // Werkstatt
    ...auftragProviders,

    //Lager
    ...lagerCoreProviders,
    ...palettenUmlagerungProviders,
    ...lagerplatzUmlagerungProviders,
    ...lagerkorrekturProviders,
    ...lagerInventurProviders,
    ...kommissionierungProviders,
    ...lagerstandProviders,
    ...lagerkorrekturArtikelProviders,

    ...platform.providers,
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const store = inject(Store);

        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: 'zentrallager',
            language: 'de',
            translations: de,
            priority: 1,
          }
        );

        store.commit(
          SOFTLINE_FEATURE_TRANSLATION,
          TranslationStore.mutations.add,
          {
            module: 'werkstatt',
            language: 'de',
            translations: deWerkstatt,
            priority: 1,
          }
        );
      },
      multi: true,
    },
  ],
};
