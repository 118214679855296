import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  Input,
} from '@angular/core';
import { SelectOptionBase } from './select-option-base';

@Directive({
  selector: 'soft-select-option',
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionDirective),
    },
  ],
})
export class SelectOptionDirective extends SelectOptionBase {
  @Input() id?: string;
  @Input() value: any;
  @Input() disabled = false;

  constructor(public element: ElementRef) {
    super();
  }
}
