import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HasFocusDirective } from './has-focus.directive';
import { RepeatDirective } from './repeat.directive';
import { VarDirective } from './var.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { SetFocusDirective } from "./set-focus.directive";

@NgModule({
  declarations: [
    VarDirective,
    HasFocusDirective,
    RepeatDirective,
    ClickOutsideDirective,
    ScrollIntoViewDirective,
  ],
  imports: [CommonModule, FormsModule, SetFocusDirective],
  exports: [
    VarDirective,
    HasFocusDirective,
    RepeatDirective,
    ClickOutsideDirective,
    ScrollIntoViewDirective,
    SetFocusDirective
  ],
  providers: [],
})
export class UiCoreDirectivesModule {}
