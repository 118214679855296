<soft-entity-input
  [readonly]="readonly"
  [placeholder]="placeholder"
  [value]="value"
  [softScan]="scan"
  [labelTypes]="labelTypes"
  [title]="title"
  [loading]="((validating$ | async) || (loading$ | async)) ?? false"
  [(isOpen)]="isOpen"
  (open)="onOpen($event)"
  (close)="onClose()"
  (query)="onQuery($event)"
  (valueChange)="setValue($event)"
  (validate)="onValidate($event)"
  (cancel)="onCancel()"
  (inputChange)="onInputChange($event)"
  #input
>
  <ng-template let-entity="entity">
    <ng-container
      *ngTemplateOutlet="
        template || defaultTemplate;
        context: { entity: entity }
      "
    ></ng-container>
  </ng-template>
  <soft-entity-picker [entities$]="filteredList$" #picker>
    <ng-container *ngIf="views && views.length > 0">
      <ng-container *ngFor="let view of views">
        <ng-template
          *ngIf="view.type === 'query'"
          [softQueryView]="view.type"
          [title]="view.title"
          #queryView="queryView"
        >
          <div class="picker-content soft-scroll overflow-y-auto">
            <soft-dynamic-form
              [definition]="view.definition"
              #form
            ></soft-dynamic-form>
          </div>
          <button
            class="soft-button soft-button-primary"
            (click)="
              queryView.query({ query: form.form.value });
              picker.activate('list')
            "
          >
            Abfragen
          </button>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'list'"
          [softDataView]="view.type"
          [title]="view.title"
          #listView="dataView"
        >
          <ng-container *ngIf="!(loading$ | async); else loadingTemplate">
            <ng-container
              *ngIf="((list$ | async)?.length ?? 0) > 0; else noDataTemplate"
            >
              <div class="picker-content">
                <div [ngClass]="view.virtual ? '' : 'no-virtual'">
                  <soft-dynamic-list
                    [definition]="view.definition"
                    [virtual]="view.virtual"
                    [items]="(filteredList$ | async) ?? []"
                    selectionMode="single"
                    #dynamicList
                  ></soft-dynamic-list>
                  <div *ngIf="(filteredList$ | async)?.length === 0">
                    {{ '#DYNAMIC.FIELD_OK.DATA.NO_DATA_FILTER' | translate }}
                  </div>
                </div>
                <div class="c toolbar">
                  <soft-input
                    class="data-filter"
                    [value]="filter$.value"
                    (valueChange)="filter$.next($event ?? '')"
                    placeholder="{{
                      '#DYNAMIC.FIELD_OK.DATA.FILTER' | translate
                    }}"
                  ></soft-input>
                  <div class="buttons no-wrap">
                    <button
                      class="soft-button secondary action-button"
                      (click)="isOpen = false"
                    >
                      {{ '#DYNAMIC.FIELD_OK.DATA.CLOSE' | translate }}
                    </button>
                    <button
                      class="soft-button primary action-button"
                      [disabled]="!dynamicList.selectedItem"
                      (click)="
                        submitPicker(dynamicList.selectedItem?.id, picker);
                        isOpen = false
                      "
                    >
                      {{ '#DYNAMIC.FIELD_OK.DATA.SUBMIT' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'table'"
          [softDataView]="view.type"
          [title]="view.title"
          #listView="dataView"
        >
          <ng-container *ngIf="!(loading$ | async); else loadingTemplate">
            <ng-container
              *ngIf="((list$ | async)?.length ?? 0) > 0; else noDataTemplate"
            >
              <div class="picker-content">
                <div [ngClass]="view.virtual ? '' : 'no-virtual'" class="!overflow-auto">
                  <soft-dynamic-table
                    [definition]="view.definition"
                    [virtual]="view.virtual"
                    [rows]="(filteredList$ | async) ?? []"
                    [class]="view.class"
                    selectionMode="single"
                    (rowDblClick)="submitPicker($event?.id, picker); isOpen = false"
                    #dynamicTable
                  ></soft-dynamic-table>
                  <div *ngIf="(filteredList$ | async)?.length === 0">
                    {{ '#DYNAMIC.FIELD_OK.DATA.NO_DATA_FILTER' | translate }}
                  </div>
                </div>
                <div class="c toolbar">
                  <soft-input
                    class="data-filter"
                    [value]="filter$.value"
                    (valueChange)="filter$.next($event ?? '')"
                    placeholder="{{
                      '#DYNAMIC.FIELD_OK.DATA.FILTER' | translate
                    }}"
                  ></soft-input>
                  <div class="buttons no-wrap">
                    <button
                      class="soft-button secondary action-button"
                      (click)="isOpen = false"
                    >
                      {{ '#DYNAMIC.FIELD_OK.DATA.CLOSE' | translate }}
                    </button>
                    <button
                      class="soft-button primary action-button"
                      [disabled]="!dynamicTable.selectedItem"
                      (click)="
                        submitPicker(dynamicTable.selectedItem?.id, picker);
                        isOpen = false
                      "
                    >
                      {{ '#DYNAMIC.FIELD_OK.DATA.SUBMIT' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'masterDetail'"
          [softDataView]="view.type"
          [title]="view.title"
          #masterDetailView="dataView"
        >
          <ng-container *ngIf="!(loading$ | async); else loadingTemplate">
            <ng-container
              *ngIf="((list$ | async)?.length) ?? 0 > 0; else noDataTemplate"
            >
              <soft-master-detail
                [data]="$any(list$ | async)"
                [masterTemplate]="masterTemplate"
                [detailTemplate]="detailTemplate"
                class="picker-content soft-scroll master-detail"
                #masterDetail
              >
              </soft-master-detail>
              <ng-template #masterTemplate let-data="data">
                <soft-dynamic-object
                  [definition]="view.masterDefinition"
                  [value]="data"
                ></soft-dynamic-object>
              </ng-template>
              <ng-template #detailTemplate let-data="data">
                <div *ngIf="masterDetail.view === 'detail'">
                  <a
                    class="soft-link"
                    (click)="masterDetail.selected = undefined"
                    >Zurück</a
                  >
                </div>
                <soft-dynamic-object
                  [definition]="view.detailDefinition"
                  [value]="data"
                ></soft-dynamic-object>
              </ng-template>

              <button
                class="soft-button primary mt-2 ml-4"
                (click)="
                  submitPicker(masterDetail.selected?.id, picker);
                  isOpen = false
                "
              >
                Auswählen
              </button>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>

    <!--Default Views-->
    <ng-container *ngIf="!views || views.length === 0">
      <ng-template
        [softQueryView]="'query'"
        [visible]="showQuery"
        title="{{ '#DYNAMIC.FIELD_OK.QUERY.TITLE' | translate }}"
        #queryView="queryView"
      >
        <div *ngIf="queryDefinition$ | async as queryDefinition">
          <div class="query-picker-content soft-scroll">
            <soft-dynamic-form
              class="dialogHeight"
              [definition]="$any(queryDefinition)"
              #dynamicForm
            ></soft-dynamic-form>
          </div>
          <div class="soft-container row end">
            <button
              class="soft-button primary mt-2 ml-4"
              (click)="
                queryView.query({ query: getDirtyValues(dynamicForm.form) })
              "
            >
              {{ '#DYNAMIC.FIELD_OK.QUERY.SUBMIT' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template
        [softDataView]="'data'"
        title="{{ '#DYNAMIC.FIELD_OK.DATA.TITLE' | translate }}"
        let-entities$="entities$"
      >
        <ng-container *ngIf="filteredList$ | async as entities">
          <ng-container *ngIf="entities.length > 0">
            <div>
              <div class="picker-content soft-scroll overflow-y-auto">
                <soft-dynamic-table
                  [definition]="$any(dataDefinition$ | async)"
                  tableClass="soft-table striped"
                  selectionMode="single"
                  [rows]="entities"
                  (rowDblClick)="
                    submitPicker($event?.id, picker); isOpen = false
                  "
                  #dataTable
                ></soft-dynamic-table>
              </div>
              <div class="soft-container row end">
                <button
                  class="soft-button primary mt-2 ml-4"
                  [disabled]="!dataTable.selectedItem?.id"
                  (click)="
                    submitPicker(dataTable.selectedItem?.id, picker);
                    isOpen = false
                  "
                >
                  {{ '#DYNAMIC.FIELD_OK.DATA.SUBMIT' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !(validating$ | async) &&
              !(loading$ | async) &&
              entities.length === 0
            "
          >
            {{ '#DYNAMIC.FIELD_OK.DATA.NO_DATA' | translate }}
          </ng-container>
        </ng-container>
        <soft-loading-spinner *ngIf="loading$ | async"></soft-loading-spinner>
      </ng-template>
    </ng-container>
  </soft-entity-picker>
</soft-entity-input>
<ng-template let-entity="entity" #defaultTemplate>
  {{ entity | json }}
</ng-template>

<ng-template #loadingTemplate>
  <div class="c row center">
    <soft-loading-spinner></soft-loading-spinner>
  </div>
</ng-template>
<ng-template #noDataTemplate>
  <div class="c row center p-2" *ngIf="input.input.length < inputMinLength">
    {{
      '#DYNAMIC.FIELD_OK.DATA.MIN_LENGTH'
        | translate
        | interpolate: { length: inputMinLength }
    }}
  </div>
  <div
    class="c row center p-2"
    *ngIf="
      (!inputMinLength || input.input.length >= inputMinLength) &&
      !(validating$ | async) && !(loading$ | async)
    "
  >
    {{ '#DYNAMIC.FIELD_OK.DATA.NO_DATA' | translate }}
  </div>
</ng-template>
