import {
  createAction,
  createGetter,
  equals,
  LazyRemoteCollectionStore, on,
  select, SOFTLINE_STORE_ENTITY_SERVICE,
  StoreBuilder,
  TemplateStore
} from "@softline/core";
import {AuftragArtikel} from '../data/auftrag-artikel';
import {ArtikelData} from '../components/auftrag-artikel-card/auftrag-artikel-card.component';
import {Einheit} from '../data/einheit';
import {forkJoin, lastValueFrom} from 'rxjs';

export type WerkstattStempelerfassungState = LazyRemoteCollectionStore.State<any>

export class AuftragArtikelStoreExtension {
  static mutations = {}

  static actions = {
    removeArtikel: createAction<WerkstattStempelerfassungState, AuftragArtikel[], AuftragArtikel[]>('werkstaettenauftrag/auftragArtikel/removeArtikel')
  }

  static getters = {
    allGrouped: createGetter<WerkstattStempelerfassungState, ArtikelData[]>('werkstaettenauftrag/auftragArtikel/allGrouped')
  }

  static feature = {
    initialState: {
      ...LazyRemoteCollectionStore.feature.initialState
    },
    actions: [
      on(this.actions.removeArtikel, async ({ injector, params, commit, featureName }) => {
        const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE)

        const result = await lastValueFrom(forkJoin(params.map(o => service.delete(o))));
        console.log('result: ', result);
        commit(featureName, LazyRemoteCollectionStore.mutations.removeMany, params);

        return result
      })
    ],
    mutations: [],
    getters: [
      select(this.getters.allGrouped, ({ state }) => Object.values(state.entities).reduce(
        (p, c) => {
          p = [...p];
          const key = { id: c.item.id, c1: c.customization?.ARTBEZ1, c2: c.customization?.ARTBEZ2 };

          let index = p.findIndex(o => equals(o.key, key));

          if (index === -1)
            index = p.push({ key, entities: [], quantity: 0, artikel: null, customization: null, einheit: null }) - 1;

          const group = p[index];
          group.entities.push(c);
          group.artikel = c.item;

          if (group?.quantity) {
            group.quantity += c?.quantity ?? 0;
          } else {
            group.quantity = c?.quantity ?? 0;
          }

          group.customization = c.customization;
          group.einheit = c?.unit as Einheit;
          return p;
        }, [] as ArtikelData[]
      ))
    ]
  }
}

export const WerkstattAuftragArtikelStore = new StoreBuilder(LazyRemoteCollectionStore.create<AuftragArtikel>())
  .add(TemplateStore.create<AuftragArtikel>())
  .add(AuftragArtikelStoreExtension)
  .value
