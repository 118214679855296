import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Store} from "@softline/core";
import {EditContextDialogComponent} from "../../../dialogs/edit-context-dialog/edit-context-dialog.component";
import {SOFTLINE_FEATURE_UTILITIES_FIRMA, SOFTLINE_FEATURE_UTILITIES_VKFORGS} from '../../../../utilities.shared'
import {map, switchMap} from "rxjs/operators";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {RefreshService} from "@softline/application";
import {VkforgStore} from '../../../vkforg.store';
import {combineLatest, Observable} from 'rxjs';
import {FirmaStore} from '../../../firma.store';

@Component({
  selector: 'soft-account-context-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vkforg-context-widget.component.html',
  styleUrls: ['./vkforg-context-widget.component.scss'],
})
export class VkforgContextWidgetComponent implements OnInit {

  readonly vkforgs$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_VKFORGS,
    VkforgStore.getters.all
  );

  readonly firmen$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_FIRMA,
    FirmaStore.getters.all
  );

  readonly context$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  ) as Observable<any>;

  readonly vkforg$ = combineLatest([this.vkforgs$, this.context$]).pipe(
    map(([o, context]) => o.find(v => v.id === context?.vkforgid) ?? o[0])
  );

  readonly firma$ = combineLatest([this.firmen$, this.vkforg$]).pipe(
    map(([firmen, vkforg]) => firmen.find(o => o?.id === vkforg?.idfirma))
  );

  readonly user$ = this.context$
    .pipe(
      switchMap(context => this.store.observe(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.getters.data,
        (context as any)
      ))
    );

  constructor(private store: Store, private refreshService: RefreshService) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([
      await this.store.dispatch(
        SOFTLINE_FEATURE_UTILITIES_VKFORGS,
        VkforgStore.actions.loadMany,
        { clear: true }
      ),
      await this.store.dispatch(
        SOFTLINE_FEATURE_UTILITIES_FIRMA,
        FirmaStore.actions.loadMany,
        { clear: true }
      )
    ]);
  }

  async onChangeAccount(): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      component: EditContextDialogComponent,
      dismiss: {
        backdrop: true,
        escape: true,
        button: true
      }
    });

    if (result === 'DISMISSED' || !result)
      return;

    await this.refreshService.refresh()
  }
}
