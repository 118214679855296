import * as moment from 'moment';
import { DateService } from './date.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MomentDateService extends DateService {
  today(): string {
    return moment().startOf('day').toISOString(true);
  }

  now(): string {
    return moment().toISOString(true);
  }
}
