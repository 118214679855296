<ng-container *ngIf="!value">
  <input
    class="mr-2"
    [value]="value || null"
    (input)="onInput(inputComponent.value)"
    [readOnly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.ENTITY_INPUT.PLACEHOLDER' | translate
    }}"
    #inputComponent
  />
  <div class="entity-picker" *ngIf="isOpen">
    <h4 class="title text-xl font-bold p-2" *ngIf="title">{{ title }}</h4>
    <ng-content></ng-content>
    <span
      class="soft-backdrop mobile-only"
      @fadeIn
      *ngIf="isOpen"
      (click)="onClose()"
    ></span>
  </div>
  <ng-container *ngIf="!loading">
    <span
      class="ml-4 pointer"
      *ngIf="isOpen || inputComponent.value.length > 0"
      (click)="inputComponent.value = ''; onClose(); $event.stopPropagation()"
    >
      <i class="fas fa-times"></i>
    </span>
    <span
      class="ml-4 pointer order-100"
      *ngIf="!readonly"
      (click)="onOpen(inputComponent.value); $event.stopPropagation()"
    >
      <i class="fas fa-database"></i>
    </span>
  </ng-container>
  <ng-container *ngIf="loading">
    <i class="fas fa-spinner fa-spin ml-4"></i>
    <span
      class="ml-4 pointer order-100"
      (click)="cancel.emit(); $event.stopPropagation()"
    >
      <i class="fas fa-times"></i>
    </span>
  </ng-container>
</ng-container>
<ng-container *ngIf="value">
  <div class="content-value width-100">
    <ng-container
      *ngTemplateOutlet="entityTemplate; context: $any({ entity: value })"
    ></ng-container>
  </div>
  <i
    class="fas fa-times ml-4 order-100"
    *ngIf="!readonly"
    (click)="setValue(null)"
  ></i>
</ng-container>
