import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { Subscription } from 'rxjs';
import { SelectOptionBase } from './select-option/select-option-base';
import { SelectOptionHeaderDirective } from './select-option/select-option-header.directive';
import { SelectOptionSeparatorDirective } from './select-option/select-option-separator.directive';
import { SelectOptionDirective } from './select-option/select-option.directive';

@Component({
  selector: 'soft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor
{
  private subscription?: Subscription;

  selectedOption: SelectOptionDirective | undefined;
  isOpen: boolean = false;

  @ContentChildren(SelectOptionBase) options!: QueryList<any>;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};
  private _value: any;

  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() placeholder?: string;

  get value(): any {
    return this.selectedOption?.value;
  }
  @Input()
  set value(value: any) {
    this._value = value;
    if (equals(value, this.selectedOption?.value)) return;
    this.selectedOption = this.options?.find((o) => equals(o.value, value));
  }
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.selectedOption = this.options?.find((o) =>
      equals(o.value, this._value)
    );
    this.subscription = this.options.changes.subscribe((changes) => {
      this.selectedOption = this.options?.find((o) =>
        equals(o.value, this._value)
      );
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onClick(): void {
    if (this.readonly) {
      this.isOpen = false;
      return;
    }
    this.isOpen = !this.isOpen;
  }

  onSelect(value: any): void {
    this.value = value;
    this.isOpen = false;
    this.onChange(value);
    this.onTouch();
    this.valueChange.emit(value);
  }

  onOutsideClick(): void {
    if (this.isOpen) this.isOpen = false;
    this.onTouch();
  }

  isOption(value: SelectOptionBase): value is SelectOptionDirective {
    return value instanceof SelectOptionDirective;
  }
  isSeparator(
    value: SelectOptionBase
  ): value is SelectOptionSeparatorDirective {
    return value instanceof SelectOptionSeparatorDirective;
  }
  isHeader(value: SelectOptionBase): value is SelectOptionHeaderDirective {
    return value instanceof SelectOptionHeaderDirective;
  }
}
