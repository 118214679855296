import {EntityListDataView} from '@softline/dynamic';

export const sellingTypeListViewDefinition: EntityListDataView = {
  type: 'list',
  title: 'Verkaufsart',
  virtual: { itemHeight: 54, display: {default: 8, small: 4} },
  definition: {
    type: 'object',
    name: '',
    class: 'px-2',
    definitions: [
      {
        type: 'string',
        class: 'soft-overline text-sm',
        name: 'id'
      },
      {
        type: 'string',
        class: 'text-base font-semibold',
        name: 'bezeichnung'
      }
    ]
  }
};
