import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  containsText,
  Dictionary,
  equals,
  getValue,
  SOFTLINE_SERVICE_UUID,
  Store,
} from '@softline/core';
import { LabelType } from '@softline/application';
import {
  EntityInputComponent,
  EntityPickerComponent,
} from '@softline/ui-core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { EntityView, FieldOkSort } from '../../data/definitions';
import { SOFTLINE_FEATURE_FIELD_OK } from '../../dynamic.shared';
import * as FieldOkComponentStore from '../../field-ok-component.store';
import { FieldOkParameters } from '../../data/field-ok';

@Component({
  selector: 'soft-field-ok',
  templateUrl: './field-ok.component.html',
  styleUrls: ['./field-ok.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldOkComponent),
      multi: true,
    },
  ],
})
export class FieldOkComponent<T>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  private componentId = this.uuid();
  private _value: T | null = null;
  private queryParams!: FieldOkParameters;

  isOpen = false;

  dataDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.dataDefinition,
    this.componentId
  );
  queryDefinition$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.queryDefinition,
    this.componentId
  );
  loading$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.loading,
    this.componentId
  );
  validating$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.validating,
    this.componentId
  );

  filter$ = new BehaviorSubject<string>('');
  debouncedFilter$ = this.filter$.pipe(debounceTime(50));
  list$ = this.store
    .observe(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.list,
      this.componentId
    )
    .pipe(map((o) => o ?? []));
  filteredList$ = combineLatest([this.list$, this.debouncedFilter$]).pipe(
    map(([entities, filter]) =>
      filter.length === 0
        ? entities
        : entities.filter((o) => containsText(o, filter, true))
    ),
    map((o) => [...o].sort((f, s) => this.sortQuery(f, s, this.sort)))
  );

  @Input() name = '';
  @Input() title?: string | null;
  @Input() parameters: object = {};
  @Input() maxAbfrageResults?: number;
  @Input() readonly = false;
  @Input() placeholder?: string | null;
  @Input() template?: TemplateRef<any>;
  @Input() autoLoad = false;
  @Input() showQuery = false;
  @Input() scan = false;
  @Input() labelTypes?: LabelType | LabelType[];
  @Input() resultMapping?: Dictionary<string>;
  @Input() mapKeysOnly = false;
  @Input() inputMinLength = 0;
  @Input() views?: EntityView[];
  @Input() sort?: FieldOkSort[];

  @ViewChild('input', { static: false }) input!: EntityInputComponent<unknown>;
  @ViewChild('picker', { static: false })
  picker!: EntityPickerComponent<unknown>;

  @Input()
  get value(): T | null {
    return this._value;
  }
  set value(value: T | null) {
    this.setValue(value, true);
  }
  @Output() valueChange = new EventEmitter<T | null>();

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private cdRef: ChangeDetectorRef,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.addOrPatch,
      { key: this.componentId, component: {} }
    );
  }

  async ngOnDestroy(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.remove,
      this.componentId
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.cancel,
      this.componentId
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  setValue(value: any, preventValueChange: boolean = false): void {
    if (this.resultMapping) value = this.mapResult(value, this.resultMapping);
    if (equals(this._value, value)) return;
    this._value = value;
    this.onChange(this._value);
    this.onTouch();
    if (!preventValueChange) this.valueChange.emit(value);
  }

  async onValidate(input: string): Promise<void> {
    this.onTouch();
    if (this.value || input.length < this.inputMinLength) return;

    if (input.length < this.inputMinLength) return;

    this.filter$.next('');
    this.queryParams = this.getQueryParams(input);

    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.actions.validate,
        {
          componentId: this.componentId,
          params: this.queryParams,
          name: this.name,
        }
      );
      this.cdRef.markForCheck();

      if (!Array.isArray(result)) this.setValue(result);
      else {
        this.isOpen = true;
        if (this.showQuery) this.picker.activate('data');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async onInputChange(input: string): Promise<void> {
    if (!this.isOpen) return;

    this.filter$.next(input);
  }

  async onQuery(query: { input?: string; query?: object }): Promise<void> {
    if ((query?.input?.length ?? 0) < this.inputMinLength) return;

    this.filter$.next('');

    this.queryParams = this.getQueryParams(query?.input ?? null, query?.query ?? {});

    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.actions.query,
        {
          componentId: this.componentId,
          params: this.queryParams,
          name: this.name,
        }
      );
      this.cdRef.markForCheck();
      if (this.showQuery) this.picker.activate('data');
    } catch (e) {
      console.log(e);
    }
  }

  private sortQuery(
    first: object,
    second: object,
    sort?: FieldOkSort[]
  ): number {
    if (!sort) return 0;
    const traverse = [...sort];
    let current: FieldOkSort | undefined;
    while ((current = traverse.shift())) {
      let firstValue = getValue(first, current.property);
      let secondValue = getValue(second, current.property);
      if (current.type === 'numeric') {
        firstValue = +firstValue;
        secondValue = +secondValue;
      }

      if (first === second) continue;

      const direction = current.direction === 'desc' ? -1 : 1;
      return (firstValue < secondValue ? -1 : 1) * direction;
    }
    return 0;
  }

  async onOpen(input: string): Promise<void> {
    this.filter$.next('');
    const queryDefinition = this.store.get(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.queryDefinition,
      this.componentId
    );
    const list = this.store.get(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.list,
      this.componentId
    );
    if (this.showQuery && list && list.length === 0)
      this.picker.activate('query');
    if (this.showQuery && queryDefinition === undefined && !this.views)
      await this.loadQueryDefinition();
    if (this.autoLoad) await this.onQuery({ input });
  }

  async onClose(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.addOrPatch,
      { key: this.componentId, component: { list: undefined } }
    );
  }

  async onCancel(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.cancel,
      this.componentId
    );
    this.isOpen = false;
  }

  private async loadQueryDefinition(): Promise<void> {
    const params = this.getQueryParams(null);

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.actions.loadQueryDefinition,
        { componentId: this.componentId, params, name: this.name }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async submitPicker(
    id: string,
    picker: EntityPickerComponent<unknown>
  ): Promise<void> {
    let result = await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.loadResult,
      {
        componentId: this.componentId,
        params: this.queryParams,
        name: this.name,
        id,
      }
    );
    if (!result) return;

    // if (this.resultMapping) result = this.mapResult(result, this.resultMapping);
    picker.submit.emit(result);
  }

  getDirtyValues(form: UntypedFormGroup): object {
    const returnValue: Dictionary<any> = {};
    for (const [key, control] of Object.entries(form.controls))
      if (control.dirty) returnValue[key] = control.value;

    return returnValue;
  }

  private mapResult(result: any, resultMap: Dictionary<string>): any {
    if (!result) return result;
    const mappedResult: Dictionary<unknown> = {};
    for (const [key, value] of Object.entries(result)) {
      let resultKey = resultMap[key];
      if(resultKey || !this.mapKeysOnly)
        mappedResult[resultKey ?? key] = value;
    }
    return mappedResult;
  }

  private getQueryParams(input: string | null, extraQuery?: object): FieldOkParameters {
    const parameters = extraQuery ? { ...this.parameters, ...extraQuery } : this.parameters;

    return this.maxAbfrageResults
      ? {
        filter: input,
        multiValued: false,
        parameters,
        maxAbfrageResults: this.maxAbfrageResults ?? -1
      }
      : {
        filter: input,
        multiValued: false,
        parameters
      };
  }
}
