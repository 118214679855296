<ng-container *ngIf="steps.length > 0 && currentIndex <= (steps.length - 1)">
  <div class="flex flex-col w-full text-center lg:hidden">
    <div class="w-full bg-gray-300 h-2.5 border border-solid border-secondary-500 mt-4">
      <div class="bg-primary h-2" [style]="{width: ((currentIndex + 1)*100)/(steps.length + 1)+'%'}"></div>
    </div>
  </div>

  <div class="flex-col w-full text-center hidden lg:flex">
    <div class="flex items-center justify-evenly w-full relative mt-6 pt-4">
      <div class="h-[1px] w-full bg-gray-300 absolute left-0 right-0 inline-block">&nbsp;</div>
      <div class="h-[1px] w-full bg-primary absolute left-0 inline-block" [style]="{width: ((currentIndex + 1)*100)/(steps.length + 1)+'%'}">&nbsp;</div>
      <div class="w-full absolute -top-6 bottom-3 left-0 right-0 inline-block bg-surface">&nbsp;</div>

      <div *ngFor="let step of steps; let i = index" class="relative flex flex-col justify-center items-center z-10">
        <div [ngClass]="currentIndex < i ? 'border-gray-300 bg-gray-200' : currentIndex === i ? 'border-primary bg-surface' : 'border-primary bg-surface'"
             class="rounded-full h-6 w-6 flex items-center justify-center border-solid border">
          <div class="h-3 w-3 bg-primary rounded-full inline-block" *ngIf="currentIndex === i">&nbsp;</div>
          <i class="fas fa-check text-primary p-1 mt-[1px] text-xs" *ngIf="currentIndex > i"></i>
        </div>

        <div class="-top-6 mb-2 absolute break-none whitespace-nowrap text-xs" [ngClass]="currentIndex === i ? 'opacity-100 text-primary font-bold' : 'opacity-40 font-semibold'">
          {{ step?.header | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
