import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  isDefined,
  KeyStore,
  mutate,
  on,
  ParamsStore,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore,
} from '@softline/core';
import { FieldOkParameters } from './data/field-ok';
import { Definition } from './data/definitions';
import { FieldOkComponentService } from './services/field-ok-component.service';
import { lastValueFrom } from 'rxjs';

export interface QueryActionParameters {
  name: string;
  params: FieldOkParameters;
  token?: string;
  componentId: string;
}

export interface LoadActionParameters {
  id: string | number;
  name: string;
  params: FieldOkParameters;
  token?: string;
  componentId: string;
}

export interface DefinitionActionParameters {
  name: string;
  params: FieldOkParameters;
  token?: string;
  componentId: string;
}

export interface FieldOkComponentState {
  value?: object | null;
  list?: object[];
  queryDefinition?: Definition | null;
  dataDefinition?: Definition | null;
}

export interface State
  extends KeyStore.State,
    ParamsStore.State,
    SubscriptionStore.State,
    ActionStore.State {
  components: Dictionary<FieldOkComponentState>;
}

export const mutations = {
  action: ActionStore.mutations,
  subscription: SubscriptionStore.mutations,
  addOrPatch: createMutation<
    State,
    { key: string; component: Partial<FieldOkComponentState> }
  >('patch'),
  remove: createMutation<State, string>('remove'),
};
export const actions = {
  cancel: createAction<State, string>('cancel field ok'),
  validate: createAction<State, QueryActionParameters, object | object[]>(
    'validate'
  ),
  query: createAction<State, QueryActionParameters, object[]>('query'),
  loadResult: createAction<State, LoadActionParameters, object>('load result'),
  loadQueryDefinition: createAction<
    State,
    DefinitionActionParameters,
    Definition
  >('load query definition'),
};
export const getters = {
  action: ActionStore.getters,
  subscription: SubscriptionStore.getters,
  loading: createGetter<State, boolean, string>('loading'),
  validating: createGetter<State, boolean, string>('validating'),
  value: createGetter<State, object | null | undefined, string>('value'),
  list: createGetter<State, object[] | undefined, string>('query result'),
  queryDefinition: createGetter<State, Definition | null | undefined, string>(
    'query definition'
  ),
  dataDefinition: createGetter<State, Definition | null | undefined, string>(
    'data definition'
  ),
};

export const feature: StoreFeature<State> = {
  initialState: {
    components: {},
    ...SubscriptionStore.feature.initialState,
    ...ActionStore.feature.initialState,
    ...KeyStore.feature.initialState,
    ...ParamsStore.feature.initialState,
  },
  mutations: [
    ...ActionStore.feature.mutations,
    ...SubscriptionStore.feature.mutations,
    ...KeyStore.feature.mutations,
    ...ParamsStore.feature.mutations,
    mutate(mutations.addOrPatch, ({ state, params }) => {
      const components = { ...state.components };
      let component = { ...state.components[params.key] };
      if (!isDefined(component)) component = {};
      component = { ...component, ...params.component };
      components[params.key] = component;
      return { ...state, components };
    }),
    mutate(mutations.remove, ({ state, params }) => {
      const components = { ...state.components };
      const component = state.components[params];
      if (!isDefined(component))
        throw new Error(
          `FieldOk component store: can not remove component ${params}`
        );
      delete components[params];
      return { ...state, components };
    }),
  ],
  actions: [
    ...ActionStore.feature.actions,
    ...SubscriptionStore.feature.actions,
    on(actions.validate, async ({ params, injector, commit, featureName }) => {
      const service = injector.get(FieldOkComponentService);
      const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
      commit(featureName, KeyStore.mutations.add, token);
      commit(featureName, ParamsStore.mutations.add, { key: token, params });

      const subscription$ = SubscriptionStore.handleSubscriptionState(
        service.validate(params.name, params.params),
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        ActionStore.handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.validate.name,
          token
        )
      );
      let component: FieldOkComponentState;
      switch (result.type) {
        case 'object':
          component = { value: result.data, dataDefinition: result.definition };
          break;
        case 'query':
          component = { list: result.data, dataDefinition: result.definition };
          break;
        case 'value':
          component = { value: null };
          break;
        default:
          throw new Error(
            `FieldOK Component Store: Unknonwn result type '${result}'`
          );
      }
      commit(featureName, mutations.addOrPatch, {
        key: params.componentId,
        component,
      });

      return result.data;
    }),
    on(actions.query, async ({ params, injector, commit, featureName }) => {
      const service = injector.get(FieldOkComponentService);
      const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
      commit(featureName, KeyStore.mutations.add, token);
      commit(featureName, ParamsStore.mutations.add, { key: token, params });

      const subscription$ = SubscriptionStore.handleSubscriptionState(
        service.query(params.name, params.params),
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        ActionStore.handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.validate.name,
          token
        )
      );
      commit(featureName, mutations.addOrPatch, {
        key: params.componentId,
        component: { list: result.data, dataDefinition: result.definition },
      });
      return result.data;
    }),
    on(
      actions.loadResult,
      async ({ params, injector, commit, featureName }) => {
        const service = injector.get(FieldOkComponentService);
        const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, { key: token, params });

        const subscription$ = SubscriptionStore.handleSubscriptionState(
          service.loadResult(params.name, params.params, params.id),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            actions.validate.name,
            token
          )
        );
        commit(featureName, mutations.addOrPatch, {
          key: params.componentId,
          component: {
            value: result.data,
            dataDefinition: result.definition,
            list: undefined,
          },
        });
        return result.data;
      }
    ),

    on(
      actions.loadQueryDefinition,
      async ({ params, injector, commit, featureName }) => {
        const service = injector.get(FieldOkComponentService);
        const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, { key: token, params });

        const subscription$ = SubscriptionStore.handleSubscriptionState(
          service.loadQueryDefinition(params.name, params.params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            actions.validate.name,
            token
          )
        );
        commit(featureName, mutations.addOrPatch, {
          key: params.componentId,
          component: { queryDefinition: result },
        });
        return result;
      }
    ),

    on(actions.cancel, async ({ params, featureName, state, dispatch }) => {
      const keys = state.keys.filter(
        (o) =>
          (state.params[o] as { componentId?: string })?.componentId ===
            params && isDefined(state.subscriptions[o])
      );

      for (const key of keys)
        await dispatch(featureName, SubscriptionStore.actions.cancel, key);
    }),
  ],
  getters: [
    ...ActionStore.feature.getters,
    ...KeyStore.feature.getters,
    ...ParamsStore.feature.getters,
    ...SubscriptionStore.feature.getters,
    select(getters.loading, ({ params, state }) => {
      const states: ActionStore.ActionState[] = ['pending', 'processing'];
      return state.keys
        .filter(
          (o) =>
            (state.params[o] as { componentId?: string })?.componentId ===
            params
        )
        .map((o) => state.actions[o])
        .some(
          (o) =>
            isDefined(o) &&
            states.includes(o.state) &&
            o.action === actions.loadResult.name
        );
    }),
    select(getters.validating, ({ params, state }) => {
      const states: ActionStore.ActionState[] = ['pending', 'processing'];
      return state.keys
        .filter(
          (o) =>
            (state.params[o] as { componentId?: string })?.componentId ===
            params
        )
        .map((o) => state.actions[o])
        .some(
          (o) =>
            isDefined(o) &&
            states.includes(o.state) &&
            o.action === actions.validate.name
        );
    }),
    select(
      getters.value,
      ({ state, params }) => state.components[params]?.value
    ),
    select(getters.list, ({ state, params }) => state.components[params]?.list),
    select(
      getters.dataDefinition,
      ({ state, params }) => state.components[params]?.dataDefinition
    ),
    select(
      getters.queryDefinition,
      ({ state, params }) => state.components[params]?.queryDefinition
    ),
  ],
};
