import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DurationFormatPipe } from './format/duration-format.pipe';
import { FilterPipe } from './array/filter.pipe';
import { InPipe } from './array/in.pipe';
import { InterpolatePipe } from './format/interpolate.pipe';
import { LocalImagePipe } from './local-image.pipe';
import { PagePipe } from './array/page.pipe';
import { SecureHtmlPipe } from './sanitize/secure-html.pipe';
import { SecureImagePipe } from './sanitize/secure-image.pipe';
import { SecureUrlPipe } from './sanitize/secure-url.pipe';
import { SortFunctionPipe } from './array/sort-function.pipe';
import { SortPipe } from './array/sort.pipe';
import { ToArrayPipe } from './array/to-array.pipe';
import { FilesizePipe } from './format/filesize.pipe';
import { MinimumPipe } from './array/minimum.pipe';
import { PropertyPipe } from './format/property.pipe';
import { ValidationMessagePipe } from './form/validation-message.pipe';
import { IsValidPipe } from './form/is-valid.pipe';
import { ReplacePipe } from './format/replace.pipe';
import { GroupByPipe } from './array/group-by.pipe';
import { FirstPipe } from './array/first.pipe';
import { LastPipe } from './array/last.pipe';
import { AtIndexPipe } from './array/at-index.pipe';
import { IsImagePipe } from './is-image.pipe';
import { SinglePipe } from './array/single.pipe';
import { StoreFunctionPipe } from './specialized/store-function.pipe';
import { EqualsPipe } from './equals.pipe';
import { TrimPipe } from './format/trim.pipe';
import { TakePipe } from './array/take.pipe';
import { TakeLastPipe } from './array/take-last.pipe';
import { SkipPipe } from './array/skip.pipe';
import { StartsWithPipe } from './string/starts-with.pipe';
import { TypeOfPipe } from './type-of.pipe';
import { UtcOffsetPipe } from './utc-offset.pipe';
import { DeserializeHtmlCharacterEntitiesPipe } from './form/deserialize-html-character-entities.pipe';
import { IsArrayPipe } from './array/is-array.pipe';
import { WidgetOutletInjectorPipe } from './widgets/widget-outlet-injector.pipe';
import { CurrencyFormatPipe } from './format/currency-format.pipe';
import { CanPreviewPipe } from './can-preview.pipe';
import { IndexOfPipe } from "./array/index-of.pipe";
import { IsDefinedPipe } from "./is-defined.pipe";
import { SumPipe } from "./array/sum.pipe";
import { MapPipe } from "./array/map.pipe";
import { Base64EncodePipe } from "./string/base-64-encode.pipe";
import { FilterByPipe } from "./array/filter-by.pipe";
import { TransformPipe } from "./transform.pipe";
import { DistinctPipe } from "./array/distinct.pipe";
import { IsIsoDatePipe } from "./is-iso-date.pipe";
import { IsNaNPipe } from "./is-nan.pipe";

@NgModule({
  declarations: [
    FilterPipe,
    InterpolatePipe,
    SecureHtmlPipe,
    SecureImagePipe,
    SecureUrlPipe,
    SortPipe,
    SortFunctionPipe,
    ToArrayPipe,
    FilesizePipe,
    PagePipe,
    MinimumPipe,
    DurationFormatPipe,
    LocalImagePipe,
    PropertyPipe,
    ValidationMessagePipe,
    IsValidPipe,
    ReplacePipe,
    InPipe,
    GroupByPipe,
    FirstPipe,
    LastPipe,
    AtIndexPipe,
    IsImagePipe,
    SinglePipe,
    StoreFunctionPipe,
    EqualsPipe,
    TrimPipe,
    TakePipe,
    TakeLastPipe,
    SkipPipe,
    StartsWithPipe,
    TypeOfPipe,
    UtcOffsetPipe,
    DeserializeHtmlCharacterEntitiesPipe,
    IsArrayPipe,
    WidgetOutletInjectorPipe,
    CurrencyFormatPipe,
    CanPreviewPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IndexOfPipe,
    IsDefinedPipe,
    SumPipe,
    MapPipe,
    Base64EncodePipe,
    FilterByPipe,
    TransformPipe,
    DistinctPipe,
    IsIsoDatePipe,
    IsNaNPipe
  ],
  exports: [
    FilterPipe,
    InterpolatePipe,
    SecureHtmlPipe,
    SecureImagePipe,
    SecureUrlPipe,
    FilterPipe,
    SortPipe,
    SortFunctionPipe,
    ToArrayPipe,
    FilesizePipe,
    PagePipe,
    MinimumPipe,
    DurationFormatPipe,
    LocalImagePipe,
    PropertyPipe,
    ValidationMessagePipe,
    IsValidPipe,
    ReplacePipe,
    InPipe,
    GroupByPipe,
    FirstPipe,
    LastPipe,
    AtIndexPipe,
    IsImagePipe,
    SinglePipe,
    StoreFunctionPipe,
    EqualsPipe,
    TakePipe,
    TakeLastPipe,
    SkipPipe,
    StartsWithPipe,
    TypeOfPipe,
    UtcOffsetPipe,
    DeserializeHtmlCharacterEntitiesPipe,
    IsArrayPipe,
    WidgetOutletInjectorPipe,
    CurrencyFormatPipe,
    CanPreviewPipe,

    IndexOfPipe,
    IsDefinedPipe,
    SumPipe,
    MapPipe,
    Base64EncodePipe,
    FilterByPipe,
    TransformPipe,
    DistinctPipe,
    IsIsoDatePipe,
    IsNaNPipe
  ],
  providers: [
    FilterPipe,
    InterpolatePipe,
    SecureHtmlPipe,
    SecureImagePipe,
    SecureUrlPipe,
    FilterPipe,
    SortPipe,
    SortFunctionPipe,
    ToArrayPipe,
    FilesizePipe,
    PagePipe,
    MinimumPipe,
    DurationFormatPipe,
    LocalImagePipe,
    PropertyPipe,
    ValidationMessagePipe,
    IsValidPipe,
    ReplacePipe,
    InPipe,
    GroupByPipe,
    FirstPipe,
    LastPipe,
    AtIndexPipe,
    IsImagePipe,
    SinglePipe,
    StoreFunctionPipe,
    EqualsPipe,
    TrimPipe,
    TakePipe,
    TakeLastPipe,
    SkipPipe,
    StartsWithPipe,
    TypeOfPipe,
    WidgetOutletInjectorPipe,
    CurrencyFormatPipe,
    CanPreviewPipe,

    IndexOfPipe,
    IsDefinedPipe,
    SumPipe,
    MapPipe,
    Base64EncodePipe,
    TransformPipe,
    DistinctPipe,
    IsIsoDatePipe,
    IsNaNPipe
  ],
})
export class UiCorePipesModule {}
