import {
  ComponentRef,
  Directive,
  Injector,
  Input,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Definition } from '../data/definitions';
import { Dictionary, getValue, isDefined } from '@softline/core';
import { FormatRulePipe } from '../pipes/format-rule.pipe';
import { BooleanComponent } from '../components/atoms/values/boolean/boolean.component';
import { CurrencyComponent } from '../components/atoms/values/currency/currency.component';
import { DateComponent } from '../components/atoms/values/date/date.component';
import { EntityComponent } from '../components/atoms/values/entity/entity.component';
import { IconComponent } from '../components/atoms/values/icon/icon.component';
import { ImageComponent } from '../components/atoms/values/image/image.component';
import { NumberComponent } from '../components/atoms/values/number/number.component';
import { SelectComponent } from '../components/atoms/values/select/select.component';
import { StringComponent } from '../components/atoms/values/string/string.component';
import { TextComponent } from '../components/atoms/values/text/text.component';
import {
  TYPE_ACTION_COMPONENT,
  TYPE_CONTAINER_COMPONENT,
  TYPE_LIST_COMPONENT,
  TYPE_OBJECT_COMPONENT,
} from '../dynamic.shared';
import { DrawComponent } from "../components/atoms/values/draw/draw.component";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[softDynamicValueField]',
})
export class DynamicValueFieldDirective implements OnInit {
  private _value!: any;
  private component?: ComponentRef<any>;

  @Input() definition!: Definition;
  @Input() get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;
    if (this.component) {
      const name = (this.definition as { name: string })?.name;
      const propertyValue =
        !isDefined(name) || name === ''
          ? this.value
          : getValue(this.value, name);
      this.component.instance.value = propertyValue;
    }
  }

  constructor(
    private container: ViewContainerRef,
    private injector: Injector,
    private format: FormatRulePipe
  ) {}

  ngOnInit(): void {
    if (!this.definition) return;
    this.createComponent(this.definition);
  }

  private createComponent(definition: Definition): void {
    const locale = 'de';

    let type: Type<any> | undefined;
    const name = (definition as { name: string })?.name;

    const value =
      !isDefined(name) || name === '' ? this.value : getValue(this.value, name);
    let params: Dictionary<unknown> = { value };
    switch (definition.type) {
      case 'action':
        type = this.injector.get(TYPE_ACTION_COMPONENT);
        params = {
          action: definition.action,
          params: definition.params,
          definition: definition.definition,
          control: definition.control,
          context: this.value,
        };
        break;
      case 'boolean':
        type = BooleanComponent;
        params = {
          control: definition.control,
        };
        break;
      case 'currency':
        type = CurrencyComponent;
        params = {
          display: definition.display,
          currencyCode: isDefined(definition.codeName)
            ? getValue(this.value, definition.codeName)
            : definition.code,
          format: definition.format,
          position: definition.position,
        };
        break;
      case 'date':
        type = DateComponent;
        params = {
          format: definition.format,
        };
        break;
      case 'duration':
        type = CurrencyComponent;
        params = {
          format: definition.format,
        };
        break;
      case 'entity':
      case 'fieldOk':
        type = EntityComponent;
        params = {
          format: definition.format,
        };
        break;
      case 'icon':
        type = IconComponent;
        params = {
          value: definition.icon,
        };
        break;
      case 'image':
        type = ImageComponent;
        break;
      case 'list':
        type = this.injector.get(TYPE_LIST_COMPONENT);
        params = { definition, values: value };
        break;
      case 'number':
        type = NumberComponent;
        params = {
          format: definition.format,
        };
        break;
      case 'object':
        type = this.injector.get(TYPE_OBJECT_COMPONENT);
        params = { definition };
        break;
      case 'draw':
        type = DrawComponent;
        params = { };
        break;
      case 'select':
        type = SelectComponent;
        params = {
          options: definition.options,
        };
        break;
      case 'string':
        type = StringComponent;
        params = {
          format: definition.format,
        };
        break;
      case 'text':
        type = TextComponent;
        params = { definition };
        break;
      case 'container':
        type = this.injector.get(TYPE_CONTAINER_COMPONENT);
        params = { definition };
        break;
      default:
        return;
    }

    this.component = this.container.createComponent(type);

    this.component.instance.value = value;
    for (const [key, param] of Object.entries(params))
      this.component.instance[key] = param;
  }
}
