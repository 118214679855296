<div class="flex flex-row w-full border border-primary border-solid rounded-md shadow h-20">
  <button class="soft-button primary !text-4xl w-1/4 rounded-l"
          [disabled]="disabled || value <= 0"
          (click)="decrement()"> - </button>
  <div class="flex flex-col w-1/2 justify-center items-center bg-white" [ngClass]="inputClass ? inputClass : ''">
    <input type="tel"
           class="text-xl !border-none w-full text-center !appearance-none font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
           (keydown)="$event.stopPropagation(); $event.preventDefault(); onKeyDown($event)"
           [value]="float ? (value | number:'1.0-10') : value">
    <div *ngIf="einheit" class="font-semibold color-light">{{einheit}}</div>
  </div>
  <button class="soft-button primary !text-4xl w-1/4 rounded-r"
          [disabled]="disabled || value >= (maxValue ?? 99999999)"
          (click)="increment()"> + </button>
</div>
