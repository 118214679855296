import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {BehaviorSubject, combineLatest, map, Observable, startWith} from 'rxjs';
import { containsText, Store } from "@softline/core";
import {Arbeitsbericht} from '../../data/stempel';
import {
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE
} from '../../auftrag.shared';
import {WerkstattArbeitsberichtStore} from '../../store/werkstatt-arbeitsbericht.store';
import {Auftrag} from '../../data/auftrag';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {EndActivityCommand} from '../../commands/end-activity.command';
import {Router} from '@angular/router';
import {AuftragCardComponent} from '../../components/auftrag-card/auftrag-card.component';

@Component({
  selector: 'soft-list-page',
  standalone: true,
  imports: [CommonModule, UiCoreModule, AuftragCardComponent],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit, OnDestroy, Refreshable, BackNavigable {

  readonly searchText$ = new BehaviorSubject<string>('');

  readonly tasks$: Observable<Auftrag[]> = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
    WerkstattAuftraegeStore.getters.all
  );

  readonly timeReport$: Observable<Arbeitsbericht | undefined> = this.store
    .observe(SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT, WerkstattArbeitsberichtStore.getters.active)
    .pipe(startWith(undefined));

  readonly filteredAuftraege$ = combineLatest([this.tasks$, this.searchText$]).pipe(
    map(([allTasks, searchString]) => {
      return (!searchString || searchString === '')
        ? allTasks
        : allTasks.filter(task => containsText(task, searchString, true)
        );
    })
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
    WerkstattAuftraegeStore.getters.loading
  );

  readonly loaded$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
    WerkstattAuftraegeStore.getters.loaded
  );

  constructor(
    private store: Store,
    private router: Router,
    private refreshable: RefreshService,
    private backNavigationService: BackNavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.refreshable.add(this);
    this.backNavigationService.set(this);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      { name: ListComponent, commands: this.createCommands() }
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      { title: '#WORKSHOP.TASK_OUTLINE.TITLE' }
    );

    await this.refresh()
  }

  ngOnDestroy() {
    this.refreshable.remove(this);
    this.backNavigationService.set(undefined);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      ListComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      { title: '' }
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate([]);
  }

  async refresh(): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.loadMany,
        { clear: true }
      );
    } catch (e) {
      handleRequestErrors(this.store, e)
    }
  }

  async navigateTo(auftrag: Auftrag): Promise<void> {
    await this.router.navigate(['/werkstaettenauftrag', auftrag.id]);
  }

  protected createCommands(): Command[] {
    const aktiverArbeitsbericht = this.store.get(
      SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
      WerkstattArbeitsberichtStore.getters.active
    )

    return [
      {
        class: 'menu action-menu action-menu-top title',
        name: '#WORKSHOP.TASK_DETAILS.ACTIONS.TITLE'
      },
      {
        class: 'menu main-menu main-menu-top',
        name: '#WORKSHOP.COMPONENTS.CREATE_TASK.TITLE',
        icon : 'fas fa-edit',
        execute: async () => {
          await this.router.navigate(['/werkstaettenauftrag', 'erstellen'])
        }
      },
      new EndActivityCommand(
        this.store,
        aktiverArbeitsbericht
      )
    ]
  }
}
