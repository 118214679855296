import { Component, OnInit } from '@angular/core';
import { FieldOkDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-field-ok-input',
  templateUrl: './field-ok-input.component.html',
  styleUrls: ['./field-ok-input.component.scss'],
})
export class FieldOkInputComponent
  extends DynamicInputAtom<FieldOkDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
