import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreBuilder,
  LazyRemoteCollectionStore
} from '@softline/core';
import {Arbeitsbericht} from '../data/stempel';
import {lastValueFrom} from 'rxjs';
import {ArbeitsberichtService} from '../services/arbeitsbericht.service';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';

export type WerkstattArbeitsberichtState = LazyRemoteCollectionStore.State<Arbeitsbericht> & {
  active?: Arbeitsbericht | null;
}

const collectionStore = LazyRemoteCollectionStore.create<Arbeitsbericht>()

class ArbeitsberichtStoreExtension {
  static mutations = {
    setActive: createMutation<WerkstattArbeitsberichtState, Arbeitsbericht | null>('werkstaettenauftrage/arbeitsbericht/setActive'),
  }

  static getters = {
    active: createGetter<WerkstattArbeitsberichtState, undefined, Arbeitsbericht | null>('werkstaettenauftrage/arbeitsbericht/active'),
  }

  static actions = {
    loadActive: createAction<WerkstattArbeitsberichtState, undefined, Arbeitsbericht | null>('werkstaettenauftrage/arbeitsbericht/loadActive'),
    start: createAction<WerkstattArbeitsberichtState, { id: Arbeitsbericht['id'], arbeitsbericht: Arbeitsbericht }, Arbeitsbericht | null>('werkstaettenauftrage/arbeitsbericht/start'),
    stopActive: createAction<WerkstattArbeitsberichtState, undefined, Arbeitsbericht | null>('werkstaettenauftrage/arbeitsbericht/stopActive'),
  }

  static feature = {
    getters: [
      select(this.getters.active, ({ state }) => state?.active),
    ],
    mutations: [
      mutate(this.mutations.setActive, ({ state, params }) => ({
        ...state,
        active: params
      })),
    ],
    actions: [
      on(this.actions.loadActive, async ({ featureName, commit, injector, get }) => {
        const service = injector.get(ArbeitsberichtService);
        const context = get(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
        const userId = (context as any)?.userId

        if (!userId)
          return;

        console.log(userId);
        const result = await lastValueFrom(service.load(userId));

        commit(featureName, this.mutations.setActive, result)

        return result;
      }),
      on(this.actions.start, async ({ params, featureName, commit, injector }) => {
        const service = injector.get(ArbeitsberichtService);
        const result = await lastValueFrom(service.start(params.id.toString(), params.arbeitsbericht));
        commit(featureName, this.mutations.setActive, result)
        return result;
      }),
      on(this.actions.stopActive, async ({ featureName, commit, injector }) => {
        const service = injector.get(ArbeitsberichtService);
        const result = await lastValueFrom(service.stop());
        commit(featureName, this.mutations.setActive, null)
        return result;
      })
    ],
    initialState: {
      active: null,
      ...collectionStore.feature.initialState
    }
  }
}

export const WerkstattArbeitsberichtStore = new StoreBuilder(collectionStore)
  .add(ArbeitsberichtStoreExtension)
  .value
