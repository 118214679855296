import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {defer, filter, from, map, Observable, switchMap, tap} from 'rxjs';
import {RemoteConfigProperty, RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_INVENTUR} from '../inventur.shared';
import {InventurStore} from '../store/inventur.store';
import {InventurKopf} from '../data/inventur-kopf';
import {extractRemoteConfiguration, InventurRemoteConfiguration} from '../utils/extract-remote-configuration';

export const MODULE_NAME = 'mde-inventur'

type InventurData = { kopf?: InventurKopf | null; } & InventurRemoteConfiguration

@Injectable()
export class InventurResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InventurData> {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)),
      switchMap((module) =>
        defer(() =>
          from(
            this.store.dispatch(
              SOFTLINE_FEATURE_MDE_INVENTUR,
              InventurStore.actions.loadKopf
            )
          )
        ).pipe(
          map(kopf => ({ module, kopf })),
        )
      ),
      tap(({ module, kopf }) => console.log('resolver:',  module, kopf)),
      map(({ module, kopf }) => ({
        kopf,
        ...extractRemoteConfiguration(module?.properties ?? [])
      })),
      tap(inventurData => console.log('InventurData from Resolver: ', inventurData))
    )
  }
}
