import {
  formatCurrency,
  formatNumber,
  getCurrencySymbol,
} from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { getValue, isDefined, Store } from '@softline/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SOFTLINE_FEATURE_LOCALIZATION } from '../l10n.shared';
import * as LocalizationStore from '../localization.store';
import { DateService } from '@softline/core';

export interface FormatPipeOptions {
  currency?: { code: string; display?: 'code' | 'symbol' | 'symbol-narrow' };
  module?: string;
  locale?: string;
  utcOffset?: string | null;
  preventNumberDefault?:boolean;
}

@Pipe({
  name: 'format',
  pure: false,
})
export class FormatPipe implements PipeTransform, OnDestroy {
  regex: RegExp = /{{\s?([^{}\s]*)\s?}}/g;
  private formatSubscription?: Subscription;
  private formatSubscription2?: Subscription;
  private localeSubscription?: Subscription;
  private format?: string;
  private locale?: string;

  constructor(
    private store: Store,
    private changeDetector: ChangeDetectorRef,
    private dateService: DateService,
  ) {}

  transform(
    value: string | number | object | undefined | null,
    format: string | undefined,
    options?: FormatPipeOptions
  ): string {
    if (value === undefined || value === null) return '';

    if (this.formatSubscription) this.formatSubscription.unsubscribe();
    if (this.localeSubscription) this.localeSubscription.unsubscribe();

    this.localeSubscription = this.store
      .observe(SOFTLINE_FEATURE_LOCALIZATION, LocalizationStore.getter.locale)
      .subscribe((val) => (this.locale = val));
    if (isDefined(format)) {
      this.formatSubscription = this.store
        .observe(
          SOFTLINE_FEATURE_LOCALIZATION,
          LocalizationStore.getter.format,
          format
        )
        .subscribe((val) => {
          this.format = val;
        });
      this.formatSubscription2 = this.store
        .observe(SOFTLINE_FEATURE_LOCALIZATION)
        .subscribe((val) => {
          this.changeDetector.markForCheck();
        });
    }

    switch (typeof value) {
      case 'number':
        if (!this.format && options?.preventNumberDefault)
          return '' + value;
        if (!this.format)
          return formatNumber(value, options?.locale ?? this.locale ?? 'de');
        if (options?.currency)
          return this.getCurrency(
            value,
            this.format,
            options?.currency,
            options?.locale ?? this.locale ?? 'de'
          );
        return formatNumber(
          value,
          options?.locale ?? this.locale ?? 'de',
          this.format
        );
      case 'string':
        if (!this.format)
          return '' + value;
        const date = this.formatDate(value, this.format, options?.utcOffset);
        if (date) return date;
        break;
      case 'object':
        if (!format) return JSON.stringify(value);
        return format.replace(this.regex, (substring: string, b: string) => {
          const replacement = this.getObjectReplacement(value, b);
          return isDefined(replacement) ? replacement : substring;
        });
        break;
      default:
        break;
    }
    return value.toString();
  }

  ngOnDestroy(): void {
    if (this.formatSubscription && !this.formatSubscription.closed)
      this.formatSubscription.unsubscribe();
    this.formatSubscription = undefined;

    if (this.formatSubscription2 && !this.formatSubscription2.closed)
      this.formatSubscription2.unsubscribe();
    this.formatSubscription2 = undefined;

    if (this.localeSubscription && !this.localeSubscription.closed)
      this.localeSubscription.unsubscribe();
    this.localeSubscription = undefined;
  }

  private getCurrency(
    value: number,
    format: string,
    currencyInfo: {
      code: string;
      display?: 'code' | 'symbol' | 'symbol-narrow';
    },
    locale: string
  ): string {
    let currency: string = currencyInfo.code;
    if (
      currencyInfo.display === 'symbol' ||
      currencyInfo.display === 'symbol-narrow'
    )
      currency = getCurrencySymbol(
        currencyInfo.code,
        currencyInfo.display === 'symbol' ? 'wide' : 'narrow',
        locale
      );

    return formatCurrency(value, locale, currency, currencyInfo.code, format);
  }

  private formatDate(
    value: string,
    format: string,
    utcOffset?: string | null
  ): string | undefined {
    if (value.startsWith('T'))
      value = moment(this.dateService.today()).format('YYYY-MM-DD') + value;
    else if (/^\d{2}:\d{2}(:\d{2}(.\d+)?)?$/g.test(value))
      value = `${moment(this.dateService.today()).format(
        'YYYY-MM-DD'
      )}T${value}`;
    const date = moment(value);
    if (isDefined(utcOffset)) date.utcOffset(utcOffset);
    if (date.isValid()) return date.format(format);
    return undefined;
  }

  private getObjectReplacement(value: unknown, mustache: string): string {
    let [name, format] = mustache.replace('{', '').replace('}', '').split(':');

    let replacement = getValue(value, name);
    if (!format) return replacement;

    format = format.replace("'", '');
    const resolvedFormat = this.store.get(
      SOFTLINE_FEATURE_LOCALIZATION,
      LocalizationStore.getter.format,
      format
    );
    if (!resolvedFormat) return replacement;

    switch (typeof replacement) {
      case 'string':
        const formattedDate = this.formatDate(replacement, resolvedFormat);
        if (formattedDate) replacement = formattedDate;
        break;
      case 'number':
        replacement = formatNumber(
          replacement,
          this.locale ?? 'de',
          resolvedFormat
        );
        break;
      default:
        break;
    }
    return replacement;
  }
}
