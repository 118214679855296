import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxOptionDirective } from './combo-box-option/combo-box-option.directive';
import { serializeHtmlCharacterEntitiesFunction } from "../../../functions/html-character-entities.function";

@Component({
  selector: 'soft-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true,
    },
  ],
})
export class ComboBoxComponent implements ControlValueAccessor {
  isOpen = false;

  get value(): any {
    return this._value;
  }
  @Input()
  set value(value: any) {
    if (value !== undefined && this._value !== value) {
      this._value = value;
      this.onChange(value);
    }
  }

  @Input() selectOnFocus = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() readonly = false;
  @Input() placeholder?: string;
  @Input() escapeHtml = false;

  private _value: any;

  @ContentChildren(ComboBoxOptionDirective)
  options!: QueryList<ComboBoxOptionDirective>;

  private onChange: Function = () => {};
  onTouch: Function = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  onSelect(value: any): void {
    this.isOpen = false;
    this.setValue(value);
  }

  onOutsideClick(): void {
    if (this.isOpen) this.isOpen = false;
  }

  selectText(target: EventTarget | null): void {
    if (this.selectOnFocus && target instanceof HTMLInputElement)
      target.select();
  }

  setValue(value: string | null): void {
    if (value && this.escapeHtml)
      value = serializeHtmlCharacterEntitiesFunction(value);
    this.value = value;

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
