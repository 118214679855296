import {Command} from '@softline/application';
import {Router} from '@angular/router';
import {Store} from '@softline/core';
import {Auftrag} from '../data/auftrag';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {CloseAuftragDialogComponent} from '../dialogs/close-task-dialog/close-task-dialog.component';

export class CloseTaskCommand implements Command {
  icon = 'fas fa-check';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.CLOSE_TASK';
  class = 'menu action-menu action-menu-top';
  execute = async () => await this.closeTask();

  constructor(
    private store: Store,
    private task: () => Auftrag,
    private router: Router
  ) {}

  private closeTask = async (): Promise<void> => {
    const result: 'success' | 'cancel' | undefined | 'DISMISSED' = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'CLOSE_TASK',
        component: CloseAuftragDialogComponent,
        data: { auftrag: this.task() },
        dismiss: {
          backdrop: true,
        },
      }
    );

    if (!result || result === 'cancel' || result === 'DISMISSED') {
      return;
    }

    await this.router.navigate(['/werkstaettenauftrag']);
  }
}
