import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG,
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE
} from '../bestellvorschlag.shared';
import {BestellvorschlagBeweStore} from '../store/bestellvorschlag-bewe.store';
import {map, Subscription} from 'rxjs';
import {BestellvorschlagArtikelDetailStore} from '../store/bestellvorschlag-artikel-detail.store';
import {BestellvorschlagStore} from '../store/bestellvorschlag.store';

@Component({
  selector: 'lib-bestellvorschlag',
  standalone: true,
  templateUrl: './bestellvorschlag.component.html',
  styleUrls: ['./bestellvorschlag.component.scss'],
  imports: [RouterModule],
})
export class BestellvorschlagComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Bestellvorschlag'
    );

    this.subscription = this.activatedRoute.data.subscribe(async ({ bestellvorschlag }) => {
      if (bestellvorschlag.showLieferdatum && !bestellvorschlag?.lieferdatum) {
        await this.router.navigate(['/mde-bestellvorschlag/liefertermin'])
      }
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: BestellvorschlagComponent,
        commands: [
          {
            name: 'Bestellvorschlag',
            class: 'menu main-menu main-menu-top',
            icon: 'fas fa-cart-plus',
            routerLink: '/mde-bestellvorschlag'
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE,
      BestellvorschlagBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      BestellvorschlagComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG,
      BestellvorschlagStore.mutations.setDate,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE,
      BestellvorschlagBeweStore.mutations.clear,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS,
      BestellvorschlagArtikelDetailStore.mutations.clear,
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
