import { Component, Input, OnInit } from '@angular/core';
import { Definition } from '../../data/definitions';

@Component({
  selector: 'soft-dynamic-skeleton',
  templateUrl: './dynamic-skeleton.component.html',
  styleUrls: ['./dynamic-skeleton.component.css'],
})
export class DynamicSkeletonComponent implements OnInit {
  @Input() definition?: Definition;

  constructor() {}

  ngOnInit(): void {}
}
