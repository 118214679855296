<div
  *ngIf="userAccounts$ | async as userAccounts"
  (click)="onChangeAccount()"
  [ngClass]="userAccounts.length > 1 ? 'bg-primary-600 hover:brightness-110' : ''"
  class="w-full flex flex-row items-center justify-between mt-3 cursor-pointer py-3 -mb-4 px-4">
  <ng-container *ngIf="activeUserAccount$ | async as activeUserAccount">
    <div class="text-primary-contrast-600" *ngIf="userAccounts.length > 1">{{activeUserAccount.name}}</div>
    <span *ngIf="userAccounts.length <= 1">{{activeUserAccount.name}}</span>
  </ng-container>

  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-primary-contrast-600 w-6 h-6" *ngIf="userAccounts.length > 1">
    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
  </svg>
</div>
