import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ToolTipComponent,
  ToolTipDirective,
} from '../directives/tool-tip.directive';
import { UiCoreDirectivesModule } from '../directives/ui-core-directives.module';
import { I18nModule } from '../i18n/i18n.module';
import { L10nModule } from '../l10n/l10n.module';
import { UiCorePipesModule } from '../pipes/ui-core-pipes.module';
import { ChipGroupComponent } from './chip/chip-group/chip-group.component';
import { ChipComponent } from './chip/chip.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { ComboBoxOptionDirective } from './form/combo-box/combo-box-option/combo-box-option.directive';
import { ComboBoxComponent } from './form/combo-box/combo-box.component';
import { TestWrapperComponent } from './form/combo-box/test-wrapper.component';
import { DateInputComponent } from './form/date-input/date-input.component';
import { DateTimeInputComponent } from './form/date-time-input/date-time-input.component';
import { DurationInputComponent } from './form/duration-input/duration-input.component';
import { EntityInputComponent } from './form/entity-input/entity-input.component';
import { DragDropDirective } from './form/file-input/directives/drag-drop.directive';
import { FileInputComponent } from './form/file-input/file-input.component';
import { ListComponent } from './form/list/list.component';
import { NumberInputComponent } from './form/number-input/number-input.component';
import { RadioGroupDirective } from './form/radio-group/radio-group.directive';
import { RadioOptionComponent } from './form/radio-group/radio-option/radio-option.component';
import { SelectOptionDirective } from './form/select/select-option/select-option.directive';
import { SelectComponent } from './form/select/select.component';
import { TimeInputComponent } from './form/time-input/time-input.component';
import { ToggleSwitchComponent } from './form/toggle-switch/toggle-switch.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MasterDetailComponent } from './master-detail/master-detail.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { DatePickerComponent } from './picker/date-picker/date-picker.component';
import { EntityPickerComponent } from './picker/entity-picker/entity-picker.component';
import { DataViewDirective } from './picker/entity-picker/views/data-view.directive';
import { InputViewDirective } from './picker/entity-picker/views/input-view.directive';
import { QueryViewDirective } from './picker/entity-picker/views/query-view.directive';
import { PullToRefreshContainerComponent } from './pull-to-refresh-container/pull-to-refresh-container.component';
import { PullToRefreshIndicatorComponent } from './pull-to-refresh-container/pull-to-refresh-indicator/pull-to-refresh-indicator.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabTitleDirective } from './tab-group/tab/tab-title/tab-title.directive';
import { TabComponent } from './tab-group/tab/tab.component';
import { ValidationContainerComponent } from './validation-container/validation-container.component';
import { ValidationMessageComponent } from './validation-container/validation-message/validation-message.component';
import { InputComponent } from './form/input/input.component';
import { SelectOptionSeparatorDirective } from './form/select/select-option/select-option-separator.directive';
import { SelectOptionHeaderDirective } from './form/select/select-option/select-option-header.directive';
import { CheckGroupDirective } from './form/check-group/check-group.directive';
import { MultiSelectComponent } from './form/multi-select/multi-select.component';
import { MultilineInputComponent } from './form/multiline-input/multiline-input.component';
import { DateNavigatorComponent } from './date-navigator/date-navigator.component';
import { CarouselComponent } from './carousel/carousel.component';
import { PasswordInputComponent } from './form/password-input/password-input.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { SignatureInputComponent } from './form/signature-input/signature-input.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { WidgetOutletComponent } from './widget-outlet/widget-outlet.component';
import { DrawInputComponent } from './form/draw-input/draw-input.component';
import { DrawPadComponent } from './draw-pad/draw-pad.component';
import { ColorInputComponent } from './form/color-input/color-input.component';
import { SwipeActionComponent } from "./swipe-container/swipe-action/swipe-action.component";
import { SwipeContainerComponent } from "./swipe-container/swipe-container.component";
import { AccordionComponent } from './accordion/accordion.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { AccordionItemComponent } from './accordion/components/accordion-item/accordion-item.component';
import { AccordionItemHeaderDirective } from './accordion/directives/accordion-item-header/accordion-item-header.directive';
import { AccordionItemContentDirective } from './accordion/directives/accordion-item-content/accordion-item-content.directive';

@NgModule({
  declarations: [
    TestWrapperComponent,

    LoadingSpinnerComponent,

    //Tab
    TabGroupComponent,
    TabComponent,
    TabTitleDirective,

    //Master Detail
    MasterDetailComponent,

    //Validation
    ValidationMessageComponent,
    ValidationContainerComponent,

    PaginatorComponent,

    //Form
    ComboBoxComponent,
    ComboBoxOptionDirective,
    DateInputComponent,
    DateTimeInputComponent,
    DurationInputComponent,
    EntityInputComponent,
    SelectComponent,
    SelectOptionDirective,
    TimeInputComponent,
    NumberInputComponent,
    FileInputComponent,
    DragDropDirective,
    CheckboxComponent,
    RadioGroupDirective,
    RadioOptionComponent,
    InputComponent,
    ToggleSwitchComponent,
    DrawInputComponent,
    ColorInputComponent,

    //Picker
    DatePickerComponent,
    EntityPickerComponent,
    QueryViewDirective,
    DataViewDirective,
    InputViewDirective,
    ChipComponent,
    ChipGroupComponent,

    ToolTipComponent,
    ToolTipDirective,
    ListComponent,
    LoadingBarComponent,
    LoadingSkeletonComponent,

    PullToRefreshIndicatorComponent,
    PullToRefreshContainerComponent,

    SelectOptionSeparatorDirective,
    SelectOptionHeaderDirective,
    CheckGroupDirective,
    MultiSelectComponent,
    MultilineInputComponent,
    DateNavigatorComponent,
    CarouselComponent,
    PasswordInputComponent,
    GalleryComponent,
    SignaturePadComponent,
    SignatureInputComponent,
    BarcodeComponent,
    WidgetOutletComponent,
    DrawPadComponent,

    AccordionComponent,
    AccordionItemComponent,

    AccordionItemHeaderDirective,
    AccordionItemContentDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    L10nModule,
    UiCorePipesModule,
    UiCoreDirectivesModule,
    PortalModule,
    CdkAccordionModule,

    SwipeContainerComponent,
    SwipeActionComponent,
  ],
  exports: [
    //Tab
    TabGroupComponent,
    TabComponent,
    TabTitleDirective,

    //Master Detail
    MasterDetailComponent,

    //Validation
    ValidationMessageComponent,
    ValidationContainerComponent,

    PaginatorComponent,

    //Form
    ComboBoxComponent,
    ComboBoxOptionDirective,
    DateInputComponent,
    DateTimeInputComponent,
    DurationInputComponent,
    EntityInputComponent,
    SelectComponent,
    SelectOptionDirective,
    TimeInputComponent,
    NumberInputComponent,
    FileInputComponent,
    DragDropDirective,
    CheckboxComponent,
    RadioGroupDirective,
    RadioOptionComponent,
    InputComponent,
    ToggleSwitchComponent,
    SignatureInputComponent,
    DrawInputComponent,
    ColorInputComponent,

    //Picker
    DatePickerComponent,
    EntityPickerComponent,
    QueryViewDirective,
    DataViewDirective,
    InputViewDirective,

    // chip
    ChipComponent,
    ChipGroupComponent,

    ToolTipComponent,
    ToolTipDirective,

    // List
    ListComponent,
    LoadingBarComponent,

    // Loading
    LoadingBarComponent,
    LoadingSpinnerComponent,
    LoadingSkeletonComponent,

    // Pull to refresh
    PullToRefreshIndicatorComponent,
    PullToRefreshContainerComponent,

    SelectOptionHeaderDirective,
    SelectOptionSeparatorDirective,
    CheckGroupDirective,
    MultiSelectComponent,
    MultilineInputComponent,
    DateNavigatorComponent,

    CarouselComponent,
    PasswordInputComponent,
    GalleryComponent,

    SignaturePadComponent,
    BarcodeComponent,
    WidgetOutletComponent,
    DrawPadComponent,

    SwipeContainerComponent,
    SwipeActionComponent,

    AccordionComponent,
    AccordionItemComponent,

    AccordionItemHeaderDirective,
    AccordionItemContentDirective

  ],
})
export class UiCoreComponentsModule {}
