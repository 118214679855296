<div class="soft-card c row rounded center no-wrap space-between" [ngClass]="(index || 0) < 1 ? 'mt-2' : 'mt-3'" *ngIf="item as item">
  <div class="text-content">
    <p class="text-sm color-light mr-2">{{ item?.artikel?.number }}</p>
    <ng-container *ngIf="item?.customization && item?.customization?.ARTBEZ1; else defaultTemplate">
      <p class="text-sm font-semibold mb-0">{{ item?.customization?.ARTBEZ1 }}</p>
      <p class="text-sm color-light mb-0">{{ item?.customization?.ARTBEZ2 }}</p>
    </ng-container>
    <ng-template #defaultTemplate>
      <p class="text-sm font-semibold mb-0">{{ item?.artikel?.name || '' }}</p>
    </ng-template>
    <div class="text-sm color-light" *ngIf="item?.artikel?.supplierItemNumber"> {{item?.artikel?.supplierItemNumber}} </div>
  </div>
  <div class="right-conten mr-2">
    <span class="soft-overline mb-0 mr-2 fluid no-wrap">{{ item?.quantity }} {{ item?.einheit?.name }}</span>
    <i class="fas fa-trash text-danger-600 ml-2" (click)="delete.emit(item)" *ngIf="removingIndex !== index; else loadingSpinner"></i>
    <ng-template #loadingSpinner>
      <soft-loading-spinner class="spinner spin"></soft-loading-spinner>
    </ng-template>
  </div>
</div>
