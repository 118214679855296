import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG, SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE
} from '../bestellerfassung.shared';
import {BestellerfassungBeweStore} from '../store/bestellerfassung-bewe.store';
import {map, Subscription} from 'rxjs';
import {BestellerfassungStore} from '../store/bestellerfassung.store';
import {BestellerfassungArtikelDetailStore} from '../store/bestellerfassung-artikel-detail.store';

@Component({
  selector: 'lib-bestellerfassung',
  standalone: true,
  templateUrl: './bestellerfassung.component.html',
  styleUrls: ['./bestellerfassung.component.scss'],
  imports: [RouterModule],
})
export class BestellerfassungComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
    BestellerfassungStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Bestellung'
    );

    this.subscription = this.activatedRoute.data.subscribe(async ({ bestellerfassung }) => {
      if (bestellerfassung?.kopf) {
        this.store.commit(
          SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
           BestellerfassungStore.mutations.setDate,
            bestellerfassung?.kopf?.belegdatum
        );

        this.store.commit(
          SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
          BestellerfassungStore.mutations.setLieferant,
          bestellerfassung?.kopf?.lieferant
        );
      }

      if (!bestellerfassung?.kopf) {
        await this.router.navigate(['/mde-bestellung/lieferant'])
      }
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: BestellerfassungComponent,
        commands: [
          {
            name: 'Bestellerfassung',
            class: 'menu main-menu main-menu-top',
            icon: 'fas fa-cart-plus',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-bestellung'
          },
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE,
      BestellerfassungBeweStore.actions.loadMany,
      { clear: true }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      BestellerfassungComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      BestellerfassungStore.mutations.setKopf,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      BestellerfassungStore.mutations.setLieferant,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      BestellerfassungStore.mutations.setDate,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      BestellerfassungStore.mutations.setLagerort,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG,
      BestellerfassungStore.mutations.setItemScanResult,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE,
      BestellerfassungBeweStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS,
      BestellerfassungArtikelDetailStore.mutations.clear
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
