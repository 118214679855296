<div class="date-preselection-container">
  <soft-select
    *ngIf="calendarService.months$ | async as months"
    [(value)]="month"
  >
    <soft-select-option
      *ngFor="let month of months; let i = index"
      [value]="i"
      >{{ month }}</soft-select-option
    >
  </soft-select>
  <soft-combo-box
    *ngIf="calendarService.years$ | async as years"
    [(value)]="year"
  >
    <soft-combo-box-option
      *ngFor="let year of years"
      [value]="year"
    ></soft-combo-box-option>
  </soft-combo-box>
</div>

<table class="unselectable" *ngIf="monthView">
  <thead>
    <tr>
      <td
        *ngFor="let weekday of calendarService.shortWeekdays$ | async"
        class="header-cell"
      >
        <span class="tbl-header">{{ weekday }}</span>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let week of monthView?.weeks">
      <td
        *ngFor="let day of week.days"
        class="calender-cell"
        (click)="onSelect(day.date)"
        (dblclick)="onSubmit(day.date)"
        [ngClass]="{
          today:
            _value.toISOString() === day.date.toISOString() && !hasSelected,
          selected: _value.toISOString() === day.date.toISOString(),
          lastMonth: month !== day.date.month()
        }"
      >
        {{ day.day | number: '2.0' }}
      </td>
    </tr>
  </tbody>
</table>
