import { ContentChild, Directive, Input } from '@angular/core';

@Directive({
  selector: 'soft-combo-box-option',
})
export class ComboBoxOptionDirective {
  @Input() value: any;

  constructor() {}
}
