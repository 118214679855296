import { Injectable } from "@angular/core";
import { AddOn, CommandStore, SOFTLINE_FEATURE_COMMANDS } from "@softline/application";
import {
  ArtikelMengePage,
  KommissionierungStore,
  SOFTLINE_FEATURE_KOMMISSIONIERUNG
} from "@softapps/lager/kommissionierung";
import { Store } from "@softline/core";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";
import {
  SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL,
  SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL
} from "../lagerkorrektur-artikel.shared";
import { LagerkorrekturArtikelStore } from "../store";
import { combineLatestWith, firstValueFrom, map } from "rxjs";
import { MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { MengenAuswahlDialog } from "../dialogs/mengen-auswahl/mengen-auswahl.dialog";
import { UmbuchungsartikelAuswahlDialog } from "../dialogs/umbuchungsartikel-auswahl/umbuchungsartikel-auswahl.dialog";

@Injectable()
export class KommissionierungAddOn implements AddOn<ArtikelMengePage> {
  type = ArtikelMengePage;

  constructor(private store: Store) {
  }

  async onAttach(host: ArtikelMengePage): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: KommissionierungAddOn,
      commands: [{
        name: '#LAGERKORREKTUR_ARTIKEL.TITLE',
        isVisible: this.store.observe(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.getters.authorized, SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL),
        icon: 'fas fa-minus-circle',
        class: 'menu action-menu action-menu-top',
        canExecute: host.auftrag$.pipe(map(o => o.lagerplatzAuswahl)),
        execute: async () => {
          const lagerplatzInhalt = await firstValueFrom(host.lagerplatz$);
          const bewe = await firstValueFrom(host.bewe$);
          const artikel = lagerplatzInhalt.inhalt.find(o => o.artikel.id === bewe?.artikelMengeOffen?.artikel?.id)
          if(!lagerplatzInhalt || !bewe || !artikel) {
            await this.store.dispatch(
              SOFTLINE_FEATURE_MESSAGE_BAR,
              MessageBarStore.actions.error,
              '#LAGERKORREKTUR_ARTIKEL.MESSAGES.ERROR.KOMMISSIONIERUNG_KEIN_ARTIKEL'
            );
            return;
          }
          host.disabled$.next(true);

          this.store.commit(
            SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL,
            LagerkorrekturArtikelStore.mutations.setLagerplatz,
            lagerplatzInhalt
          );
          this.store.commit(
            SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL,
            LagerkorrekturArtikelStore.mutations.setArtikel,
            artikel
          );

          let result = await this.store.dispatch(
            SOFTLINE_FEATURE_MODAL,
            ModalStore.actions.open(),
            {
              component: MengenAuswahlDialog,
              dismiss: true
            }
          )
          if(result === 'DISMISSED') {
            host.disabled$.next(false);
            return;
          }

          result = await this.store.dispatch(
            SOFTLINE_FEATURE_MODAL,
            ModalStore.actions.open(),
            {
              component: UmbuchungsartikelAuswahlDialog,
              dismiss: true
            }
          )
          if(result === 'DISMISSED'){
            host.disabled$.next(false);
            return;
          }
          await host.loadLagerplatzVorschlag(bewe);
          host.disabled$.next(false);
        }
      }]
    })
  }

  async onDetach(host: ArtikelMengePage): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, KommissionierungAddOn)
  }
}
