import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'isIsoDate',
  standalone: true,
  pure: true
})
export class IsIsoDatePipe implements PipeTransform {
  transform(value: string): boolean {
    const date = moment(value, false);
    return date.isValid();
  }
}
