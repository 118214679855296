import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreDirectivesModule } from '../directives/ui-core-directives.module';
import { I18nModule } from '../i18n/i18n.module';
import { ModalComponent } from './components/dialog/modal.component';
import { ModalContainerComponent } from './components/modal-container.component';
import { QuestionModalComponent } from './components/question-modal/question-modal.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { UiCorePipesModule } from '../pipes/ui-core-pipes.module';
import { StoreModule } from '@softline/core';
import { SOFTLINE_FEATURE_MODAL } from './modal.shared';
import * as ModalStore from './modal.store';
import { OptionModalComponent } from './components/option-modal/option-modal.component';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { UiCoreComponentsModule } from '../components/ui-core-components.module';
import { SignatureModalComponent } from './components/signature-modal/signature-modal.component';
import { DrawModalComponent } from './components/draw-modal/draw-modal.component';
import { FileModalComponent } from "./components/file-modal/file-modal.component";
import { TemplateModalComponent } from "./components/template-modal/template-modal.component";

@NgModule({
  declarations: [
    ModalComponent,
    ModalContainerComponent,
    QuestionModalComponent,
    NotificationModalComponent,
    OptionModalComponent,
    GalleryModalComponent,
    SignatureModalComponent,
    DrawModalComponent,
    FileModalComponent,
    TemplateModalComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    UiCorePipesModule,
    UiCoreDirectivesModule,
    UiCoreComponentsModule,
  ],
  exports: [
    ModalContainerComponent,
    QuestionModalComponent,
    NotificationModalComponent,
  ],
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalRootModule> {
    return {
      ngModule: ModalRootModule,
    };
  }
}

@NgModule({
  imports: [
    ModalModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MODAL,
      feature: ModalStore.feature,
    }),
  ],
  exports: [ModalModule],
})
export class ModalRootModule {}
