import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {map, Subscription} from 'rxjs';
import {
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS, SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE
} from '../warenverprobung.shared';
import {WarenverprobungArtikelDetailStore} from '../store/warenverprobung-artikel-detail.store';
import {WarenverprobungBeweStore} from '../store/warenverprobung-bewe.store';
import {WarenverprobungBelegeStore} from '../store/warenverprobung-belege.store';
import {WarenverprobungStore} from '../store/warenverprobung-store';

@Component({
  selector: 'soft-mde-warenverprobung',
  standalone: true,
  templateUrl: './warenverprobung.component.html',
  styleUrls: ['./warenverprobung.component.scss'],
  imports: [RouterModule],
})
export class WarenverprobungComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  readonly kopf$ = this.store.observe(
    SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
    WarenverprobungStore.getters.kopf
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Warenverprobung'
    );

    this.subscription = this.activatedRoute.data.subscribe(async (data) => {
      if (!data['warenverprobung']['kopf'] || (data['warenverprobung']['kopf']['belege']?.length || 0) < 1) {
        await this.router.navigate(['/mde-warenverprobung/auswahl/belegart'])
      }
    });

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: WarenverprobungComponent,
        commands: [
          {
            name: 'Warenverprobung',
            class: 'menu main-menu main-menu-top',
            icon: 'fas fa-vials',
            isVisible: this.kopf$.pipe(map(o => !!o)),
            canExecute: this.kopf$.pipe(map(o => !!o)),
            routerLink: '/mde-warenverprobung'
          },
        ]
      }
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      WarenverprobungComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
      WarenverprobungStore.mutations.setKopf,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
      WarenverprobungStore.mutations.setBezugsnummer,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
      WarenverprobungStore.mutations.setBelege,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE,
      WarenverprobungBeweStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE,
      WarenverprobungBelegeStore.mutations.clear
    );

    this.store.commit(
      SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS,
      WarenverprobungArtikelDetailStore.mutations.clear
    );

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
