<div class="detail-container" *ngIf="(auftrag$ | async) as task; else loadingSpinner">
  <div class="detail-content pb-16">
    <h3 class="text-3xl font-bold mt-3">{{ task?.object?.name }}</h3>

    <soft-message-bar-container [name]="'DETAIL_MESSAGE_CONTAINER'"></soft-message-bar-container>

    <section class="mt-4" *ngIf="task?.customer">
      <div class="c row center-v space-between no-wrap">
        <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.CUSTOMER.HEADER' | translate }}</h6>
        <a target="_blank" class="soft-link" [attr.href]="getGoogleLink(task?.customer)" *ngIf="task?.customer">{{ '#WORKSHOP.TASK_DETAILS.CUSTOMER.ADDRESS.GOOGLE_MAPS' | translate }}</a>
      </div>

      <p class="soft-text">{{ task?.customer?.number }}</p>
      <p class="soft-text">
        <ng-container *ngIf="task?.customer?.name1">{{ task?.customer?.name1 }} </ng-container>
        <ng-container *ngIf="task?.customer?.name2">{{ task?.customer?.name2 }} </ng-container>
        <ng-container *ngIf="task?.customer?.kurzbez && !task?.customer?.name1 && !task.customer?.name2">{{ task?.customer?.kurzbez }}</ng-container>
      </p>
      <p class="soft-text">{{ task?.customer?.strasse }}<ng-container *ngIf="task?.customer?.strassenr"> {{ task?.customer?.strassenr }}</ng-container></p>
      <ng-container *ngIf="task?.customer?.plz || task?.customer?.ort"><p class="soft-text">{{ task?.customer?.plz }} {{ task?.customer?.ort }}</p></ng-container>
    </section>

    <ng-container *ngIf="(task.customer?.phones?.length || 0) > 0 || (task.customer?.emails?.length || 0) > 0">
      <section class="mt-5">
        <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.CUSTOMER.CONTACTS.TITLE' | translate }}</h6>
        <div class="soft-container c column no-wrap center-v">
          <!-- <div class="soft-container c row no-wrap center-v space-between pt-2 pb-2" *ngFor="let element of contactDetails; let last = last" [class.border-separator]="!last">
            <span class="soft-text">{{ '#WORKSHOP.TASK_DETAILS.CUSTOMER.CONTACTS.' + element[0] | translate }}</span>
            <a class="soft-link" [href]="element[1]?.linkAction">{{ element[1]?.value }}</a>
          </div> -->
          <!-- <div class="soft-container c row no-wrap center-v space-between border-separator pt-2 pb-2">
             <span class="soft-text">Telefon Firma</span>
             <a class="soft-link">{{ task?.customer?.phoneFirma }}</a>
           </div> -->
          <div class="soft-container c row no-wrap center-v space-between pt-2 pb-2" *ngFor="let element of (task.customer?.phones || []); let last = last" [class.border-separator]="((task.customer?.emails?.length || 0) > 0) ? true : !last ">
            <span class="soft-text">{{ element?.type }}</span>
            <a class="soft-link" [href]="'tel:' + element?.number">{{ element?.number }}</a>
          </div>
          <div class="soft-container c row no-wrap center-v space-between pt-2 pb-2" *ngFor="let element of (task.customer?.emails || []); let i = index; let last = last" [class.border-separator]="!last">
            <span class="soft-text">{{ element?.type || ('Email ' + (i + 1)) }}</span>
            <a class="soft-link" [href]="'mailto:' + element?.email">{{ element?.email }}</a>
          </div>
        </div>
      </section>
    </ng-container>

    <section class="mt-5" *ngIf="task?.sellingType?.name">
      <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.SELLING_TYPE.HEADER' | translate }}</h6>
      <p class="soft-text">{{ task?.sellingType?.name }}</p>
    </section>

    <section class="mt-5" *ngIf="task?.description">
      <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.HEADER_TEXT.HEADER' | translate }}</h6>
      <p class="soft-text">{{ task?.description }}</p>
    </section>

    <section class="mt-5" *ngIf="task?.info && (task.info?.length || 0) > 0">
      <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.SHIPPING_INFO.HEADER' | translate }}</h6>
      <div class="c column" *ngFor="let info of task?.info; let i = index" [ngClass]="[i < 1 ? 'shipping-info-condensed' : 'shipping-info']">
        <p class="soft-text">{{ info }}</p>
      </div>
    </section>

    <section class="mt-5">
      <h6 class="soft-overline colored">{{ '#WORKSHOP.TASK_DETAILS.QUICK_ACTIONS.HEADER' | translate }}</h6>
      <div class="shortcuts mt-2">
        <div class="action mr-2" (click)="navigateToAddTaskItems(task.id)">
          <i class="fas fa-clipboard-list fa-2x mr-3"></i>
          <span class="action-text">{{ '#WORKSHOP.TASK_DETAILS.QUICK_ACTIONS.ADD_ITEMS' | translate }}</span>
        </div>
        <div class="action ml-2" (click)="navigateToManualWorkReport(task)">
          <i class="fas fa-user-clock fa-2x mr-3"></i>
          <span class="action-text">{{ '#WORKSHOP.TASK_DETAILS.QUICK_ACTIONS.WORK_REPORT' | translate }}</span>
        </div>
      </div>
      <p class="color-light mt-1 block text-sm">{{ '#WORKSHOP.TASK_DETAILS.QUICK_ACTIONS.DESCRIPTION' | translate }}</p>
    </section>

    <section class="mt-5" *ngIf="(auftragArtikel$ | async) as items">
      <ng-container *ngIf="!(auftragArtikelLoading$ | async) && (items?.length || 0) > 0">
        <h6 class="soft-overline colored condensed">{{ '#WORKSHOP.TASK_DETAILS.ITEMS.HEADER' | translate }}</h6>
        <soft-auftrag-artikel-card
          *ngFor="let item of items; let i = index"
          (delete)="deleteTaskItems(item?.entities || [], i)"
          [item]="item"
          [removingIndex]="(removingArtikelIndex$ | async)"
          [index]="i">
        </soft-auftrag-artikel-card>
      </ng-container>
    </section>

    <section class="mt-5 mb-3" *ngIf="(arbeitsberichte$ | async) as arbeitsberichte">
      <ng-container *ngIf="!(arbeitsberichteLoading$ | async) && (arbeitsberichte?.length || 0) > 0">
        <h6 class="soft-overline colored condensed">{{ '#WORKSHOP.TASK_DETAILS.REPORTS.HEADER' | translate }}</h6>
        <div class="task-item-list">
          <soft-arbeitsbericht-card
            *ngFor="let item of arbeitsberichte; let i = index"
            (delete)="deleteArbeitsbericht(item)"
            [item]="item"
            [index]="i">
          </soft-arbeitsbericht-card>
        </div>
      </ng-container>
    </section>

    <section class="mt-5 mb-3">
      <h6 class="soft-overline colored condensed mb-1">{{ '#WORKSHOP.TASK_DETAILS.PICTURES.HEADER' | translate }}</h6>
      <soft-archive-list [archiveKey]="task?.archiveKey"></soft-archive-list>
    </section>

    <section class="mt-5 mb-3">
      <div class="c row space-between no-wrap center-v">
        <h6 class="soft-overline colored condensed">{{ '#WORKSHOP.TASK_DETAILS.SIGN.HEADER' | translate }}</h6>
        <span class="sign-status soft-text-s color-light uppercase text-danger-600 text-sm" *ngIf="!(task?.signature)">{{ '#WORKSHOP.TASK_DETAILS.SIGN.STATUS_ERROR' | translate }}</span>
      </div>
      <!-- <div class="signature-input pos-rel mt-2" (click)="signTask(task)">
        <ng-container *ngIf="task?.signature as signature">
          <ng-container *ngIf="signature | getSignature | async as safeImageUrl; else loadingImageTemplate">
            <img [src]="safeImageUrl" alt="Signature" class="signature-image">
          </ng-container>
          <ng-template #loadingImageTemplate>
            <div class="c row center">
              <div class="soft-spinner spin"></div>
            </div>
          </ng-template>
        </ng-container>
        <small class="soft-text-xs color-light">{{ '#WORKSHOP.TASK_DETAILS.SIGN.HINT' | translate }}</small>
      </div> -->

      <soft-signature-input [value]="task?.signature ? (task?.signature | getSignature | async) : undefined" (valueChange)="signTask(task, $event)"></soft-signature-input>
    </section>
  </div>

  <button
    class="soft-button primary h-16 fill-padding mt-3 fixed bottom-0 left-0 right-0"
    (click)="closeTask(task)"
    [disabled]="(arbeitsberichteLoading$ | async)">
    <ng-container *ngIf="arbeitsberichteLoading$ | async; else content">
      <div class="soft-spinner spin small"></div>
    </ng-container>

    <ng-template #content>
      {{ '#WORKSHOP.TASK_DETAILS.CLOSE' | translate }}
    </ng-template>
  </button>
</div>

<ng-template #loadingSpinner>
  <div class="loading h-screen flex items-center justify-center text-right">
    <soft-loading-spinner class="spin"></soft-loading-spinner>
  </div>
</ng-template>
