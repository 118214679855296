import { ChangeDetectorRef, Component, ContentChildren, Input, QueryList, ViewChildren } from '@angular/core';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { CdkAccordionItem } from '@angular/cdk/accordion';

@Component({
  selector: 'soft-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {

  @Input() multi = false;

  @ContentChildren(AccordionItemComponent) readonly accordionItems?: QueryList<AccordionItemComponent>

  @ViewChildren('accordionItem') cdkAccordionItems?: QueryList<CdkAccordionItem>

  private readonly idAccordionComponentMap = new Map<string, { cdkAccordionItem: CdkAccordionItem; index: number }>();

  constructor(private cdRef: ChangeDetectorRef) {}

  toggle(item: AccordionItemComponent): void {
    const cdkAccordionItem = this.getCdkAccordionItem(item);
    cdkAccordionItem?.toggle();
  }

  isExpanded(item: AccordionItemComponent): boolean {
    const cdk = this.getCdkAccordionItem(item);
    return cdk?.expanded ?? false;
  }

  private getCdkAccordionItem(itemComponent: AccordionItemComponent): CdkAccordionItem | undefined {
    const index = this.accordionItems?.toArray()?.findIndex(o => o === itemComponent);

    if (index === undefined || index < 0) {
      return undefined;
    }

    const cdkAccordionItem = this.cdkAccordionItems?.get(index);

    if (!cdkAccordionItem) {
      return undefined;
    }

    return cdkAccordionItem;
  }
}
