import {EntityListDataView} from '@softline/dynamic';

export const customerListViewDefinition: EntityListDataView = {
  type: 'list',
  title: 'Kunden',
  virtual: {itemHeight: 94, display: {default: 5, small: 3}},
  definition: {
    type: 'object',
    name: '',
    class: 'g gc-6 gr-4 px-2 border-box no-wrap truncate',
    definitions: [
      {
        type: 'string',
        class: 'gr-1-1 gc-1-6 c row start center-v no-wrap text-sm soft-overline border-box',
        name: 'nummer'
      },
      {
        type: 'string',
        class: 'gr-2-2 gc-1-6 c row start center-v no-wrap font-semibold text-base soft-text truncate border-box',
        name: '5'
      },
      {
        type: 'string',
        class: 'gr-3-3 gc-1-6 c row start center-v no-wrap text-sm truncate border-box',
        name: '13'
      },
      {
        type: 'string',
        class: 'gr-4-4 gc-1-5 c row start center-v no-wrap text-sm color-light truncate border-box',
        name: '15'
      },
      {
        type: 'string',
        class: 'gr-4-4 gc-6-6 c row end center-v no-wrap pr-2 text-sm color-light truncate border-box',
        name: '14'
      },
    ]
  }
};
