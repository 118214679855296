import { LabelType } from '@softline/application';
import { Dictionary } from "@softline/core";
import { ClassRule, FormatRule, Rule, ValidationRule } from './rules';
import { VirtualScrollParameters } from './virtual-scroll';
import { Affect } from "./affects";
import { Filter } from "./filter";
import { Sortable } from "./sortable";

export type Definition =
  | ControlDefinition
  | GroupDefinition
  | IconDefinition
  | ActionDefinition
  | SubmitDefinition
  | ResetDefinition
  | ImageDefinition
  | TextDefinition
  | ContainerDefinition;

export type ControlDefinition =
  | ObjectDefinition
  | BooleanDefinition
  | NumberDefinition
  | StringDefinition
  | CurrencyDefinition
  | DateDefinition
  | SelectDefinition
  | MultiSelectDefinition
  | ListDefinition
  | DurationDefinition
  | EntityDefinition
  | FieldOkDefinition
  | ImageInputDefinition
  | DrawDefinition;

export type GroupDefinition =
  | PanelGroupDefinition
  | TabGroupDefinition
  | TabDefinition;

export interface DefinitionBase {
  type: string;
  title?: string;
  tooltip?: string;
  class?: string | (string | ClassRule)[];
  titleClass?: string | (string | ClassRule)[];
  visible?: boolean | Rule;
  skeleton?: string;
}

export interface ControlDefinitionBase extends DefinitionBase {
  name: string;
  readonly?: boolean | Rule;
  required?: boolean | Rule;
  validations?: ValidationRule[];
  default?: unknown;
  affects?: Affect[];
  filter?: boolean | Filter;
  sortable?: boolean | Sortable;
}

export interface GroupDefinitionBase extends DefinitionBase {
  type: 'group';
  control: string;
  definitions: Definition[];
}

export interface PanelGroupDefinition extends GroupDefinitionBase {
  control: 'div' | 'section' | 'card';
}

export interface TabGroupDefinition extends GroupDefinitionBase {
  control: 'tabGroup';
  definitions: TabDefinition[];
}

export interface TabDefinition extends GroupDefinitionBase {
  control: 'tab';
  definitions: Definition[];
}

export interface ObjectDefinition extends ControlDefinitionBase {
  type: 'object';
  definitions: Definition[];
  default?: object;
  format?: string | FormatRule;
}

export interface BooleanDefinition extends ControlDefinitionBase {
  type: 'boolean';
  default?: boolean;
  control?: 'checkbox' | 'switch';
  label?: string;
}

export interface CurrencyDefinition extends ControlDefinitionBase {
  type: 'currency';
  format?: string | FormatRule;
  display?: 'code' | 'symbol';
  codeName?: string;
  code?: string;
  position?: 'before' | 'after';
}

export interface NumberDefinition extends ControlDefinitionBase {
  type: 'number';
  format?: string | FormatRule;
  default?: number;
}

export interface StringDefinition extends ControlDefinitionBase {
  type: 'string';
  default?: string;
  control?: 'singleline' | 'multiline' | 'richtext' | 'code' | 'span';
  format?: string | FormatRule;
}

export interface DateDefinition extends ControlDefinitionBase {
  type: 'date';
  format?: string | FormatRule;
  control?: 'date' | 'time' | 'datetime';
}

export interface DurationDefinition extends ControlDefinitionBase {
  type: 'duration';
  format?: string | FormatRule;
}

export interface SelectDefinition extends ControlDefinitionBase {
  type: 'select';
  options: {
    value: any;
    title: string;
  }[];
  default?: any;
  control?: 'radio' | 'dropdown' | 'combobox' | 'list';
}

export interface MultiSelectDefinition extends ControlDefinitionBase {
  type: 'multiSelect';
  options: {
    value: any;
    title: string;
  }[];
  default?: any[];
  maxDisplayed?: number;
  direction?: 'vertical' | 'horizontal';
  control?: 'checkboxes' | 'dropdown' | 'list' | 'dialog';
}

export interface DrawDefinition extends ControlDefinitionBase {
  type: 'draw';
}

export interface ListDefinition extends ControlDefinitionBase {
  type: 'list';
  definition: ObjectDefinition;
  inputDefinition?: Definition;
  control: 'list' | 'table' | 'chips';
  default?: any[];
}

export interface EntityDefinition extends ControlDefinitionBase {
  type: 'entity';

  views?: EntityView[];

  default?: object;
  format?: string | FormatRule;

  endpoint: string;
  queryParams?: { key: string; value: string }[];
  pageSize?: number;

  inputMinLength: number;
}

export interface FieldOkDefinition extends ControlDefinitionBase {
  type: 'fieldOk';

  default?: object;
  format?: string | FormatRule;

  fieldOk: string;
  parameters?: Dictionary<unknown>;
  pageSize?: number;
  autoLoad?: boolean;
  showQuery?: boolean;
  placeholder?: string;
  resultMapping?: Dictionary<string>;

  // Limitiert die Anzahl der Ergebnisse
  maxAbfrageResults?: number;

  inputMinLength?: number;
  views?: EntityView[];
  sort?: FieldOkSort[]; //TODO: Entfernen wenn Sortierungslogik über das FOK gelöst wurde
}

export interface FieldOkSort {
  property: string;
  type?: 'numeric' | 'alphanumeric';
  direction?: 'asc' | 'desc';
}

export interface IconDefinition extends DefinitionBase {
  type: 'icon';
  icon: string;
}

export interface TextDefinition extends DefinitionBase {
  type: 'text';
  text: string;
  control?: 'div' | 'span' | 'p';
}

export interface ContainerDefinition extends DefinitionBase {
  type: 'container';
  control?: 'div' | 'span';
  definitions: Definition[];
}

export interface ImageDefinition extends DefinitionBase {
  type: 'image';
  src: string;
}

export interface ImageInputDefinition extends ControlDefinitionBase {
  type: 'imageInput';
  mode?: 'single' | 'multi';
}

export interface ActionDefinition extends DefinitionBase {
  type: 'action';
  action: string;
  params?: unknown;
  control?: 'button' | 'link';
  definition?: Definition;
  readonly?: boolean | Rule;
}

export interface SubmitDefinition extends DefinitionBase {
  type: 'submit';
  readonly?: boolean | Rule;
}

export interface ResetDefinition extends DefinitionBase {
  type: 'reset';
  readonly?: boolean | Rule;
}

export interface ScanDefinition {
  scan: true;
  labelTypes?: LabelType | LabelType[];
}

export type EntityView = EntityQueryView | EntityInputView | EntityDataView;
export type EntityQueryView = EntityFormQueryView | EntityBarcodeQueryView;
export type EntityInputView = EntityFormInputView;
export type EntityDataView = EntityMasterDetailDataView | EntityListDataView | EntityTableDataView;

export interface EntityFormQueryView {
  type: 'query';
  title: string;
  definition: ObjectDefinition;
}

export interface EntityBarcodeQueryView {
  type: 'barcode';
  title: string;
}

export interface EntityFormInputView {
  type: 'input';
  title: string;
  definition: ObjectDefinition;
}

export interface EntityMasterDetailDataView {
  type: 'masterDetail';
  title: string;
  masterDefinition: ObjectDefinition;
  detailDefinition: ObjectDefinition;
}

export interface EntityListDataView {
  type: 'list';
  title: string;
  definition: ObjectDefinition;
  virtual?: VirtualScrollParameters;
}

export interface EntityTableDataView {
  type: 'table';
  title: string;
  class: string;
  definition: ObjectDefinition;
  virtual?: VirtualScrollParameters;
}

export function isControlDefinition(
  definition: Definition | null | undefined
): definition is ControlDefinition {
  return (definition as ControlDefinitionBase)?.name !== undefined;
}

export function isScanDefinition(
  definition: any
): definition is ScanDefinition {
  return (definition as unknown as ScanDefinition).scan;
}
