import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QueryParameters } from '@softline/core';
import { BehaviorSubject } from 'rxjs';
import { EntityPickerComponent } from '../../picker/entity-picker/entity-picker.component';

@Component({
  selector: 'soft-entity-input',
  templateUrl: './entity-input.component.html',
  styleUrls: ['./entity-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityInputComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('222ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class EntityInputComponent<TEntity>
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  private _isOpen = false;

  @Input()
  get isOpen(): boolean {
    return this._isOpen;
  }
  set isOpen(value: boolean) {
    if (this._isOpen === value) return;
    this._isOpen = value;
  }
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild(EntityPickerComponent) picker: EntityPickerComponent<TEntity> | null = null;
  @ContentChild(TemplateRef) entityTemplate: TemplateRef<TEntity> | null = null;

  @Input() title?: string | null;
  @Input() value?: TEntity | null;
  @Output() valueChange: EventEmitter<TEntity | null> = new EventEmitter<TEntity | null>();

  @Input() input = '';
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() validate: EventEmitter<string> = new EventEmitter<string>();

  @Input() loading = false;
  @Input() loaded = false;

  @Output() query: EventEmitter<{ input?: string; query?: object }> =
    new EventEmitter<{ input?: string; query?: object }>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output() open = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() placeholder?: string | null;

  @ViewChild('inputComponent') inputComponent!: ElementRef;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.picker) {
      this.picker.submit.subscribe((o: TEntity) => this.setValue(o));
      this.picker.query.subscribe((o: { input?: string; query?: object }) =>
        this.query.emit(o)
      );
    }
  }

  @HostListener('keydown.f4', ['$event']) onF4(event?: KeyboardEvent): void {
    this.input = this.inputComponent.nativeElement.value;
    this.onOpen(this.inputComponent.nativeElement.value);
  }
  @HostListener('keydown.tab', ['$event']) onTab(event?: KeyboardEvent): void {
    this.input = this.inputComponent.nativeElement.value;
    this.validate.emit(this.inputComponent.nativeElement.value);
  }
  @HostListener('keydown.enter', ['$event']) onEnter(
    event?: KeyboardEvent
  ): void {
    this.input = this.inputComponent.nativeElement.value;
    this.validate.emit(this.inputComponent.nativeElement.value);
  }
  @HostListener('keydown', ['$event']) preventKeydownPropagation(event?: KeyboardEvent): void {
    event?.stopPropagation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.input = obj;
      this.value = undefined;
    } else {
      this.value = obj;
      this.input = '';
    }
  }

  onInput(input: string): void {
    this.input = input;
    this.inputChange.emit(input);
  }

  onOpen(input: string): void {
    if (!this._isOpen) {
      this._isOpen = true;
      this.isOpenChange.emit(true);
    }
    this.open.emit(input);
    this.onTouch(this.value);
  }

  onClose(): void {
    if (this._isOpen) {
      this._isOpen = false;
      this.isOpenChange.emit(false);
    }
    this.close.emit();
    this.onTouch(this.value);
  }

  setValue(value: TEntity | null): void {
    if (value === this.value) return;
    this.value = value;
    this.input = '';

    this.onChange(this.value);
    this.onTouch(this.value);
    this.valueChange.emit(this.value);
  }
}
