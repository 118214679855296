import { ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, inject, Provider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application";
import { default as de } from "../i18n/de.json";
import { SOFTLINE_PERMISSION_LAGERSTAND } from "./lagerstand.shared";

export const lagerstandProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'lagerstand',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#LAGERSTAND.APP',
        description: '#LAGERSTAND.APP_DESC',
        icon: 'fas fa-cubes',
        route: '/lagerstand',
        permission: SOFTLINE_PERMISSION_LAGERSTAND
      });
    },
    multi: true
  }
];
