import {Command} from '@softline/application';
import {Router} from '@angular/router';
import {Auftrag} from '../data/auftrag';

export class AddTaskItemCommand implements Command {
  icon = 'fas fa-clipboard-list';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.ADD_TASK_ITEM';
  class = 'menu action-menu action-menu-top';
  execute = async () => await this.navigateToAddTaskItems();

  constructor(private router: Router, private auftrag: () => Auftrag) {}

  navigateToAddTaskItems = async (): Promise<void> => {
    await this.router.navigate(['/werkstaettenauftrag', this.auftrag().id, 'artikel']);
  }
}

