import { Injector, Pipe, PipeTransform } from "@angular/core";
import { FormatRule } from '../data/rules';
import { RuleHelper } from '../utilities/rule.helper';
import { isDefined } from "@softline/core";

@Pipe({
  name: 'formatRule',
})
export class FormatRulePipe implements PipeTransform {
  constructor(private injector: Injector) {
  }

  transform(
    rule: undefined | string | FormatRule,
    value: any,
    defaultValue?: string
  ): string | undefined {
    if (!isDefined(rule))
      return defaultValue;
    return RuleHelper.getFormat(rule, value, this.injector) ?? defaultValue;
  }
}
