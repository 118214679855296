import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@softline/core';
import { ScannerModule } from '@softline/application';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { ActionInputComponent } from './components/atoms/inputs/action/action.component';
import { BooleanInputComponent } from './components/atoms/inputs/boolean/boolean-input.component';
import { DateInputComponent } from './components/atoms/inputs/date/date-input.component';
import { EntityInputComponent } from './components/atoms/inputs/entity/entity-input.component';
import { ListInputComponent } from './components/atoms/inputs/list/list-input.component';
import { NumberInputComponent } from './components/atoms/inputs/number/number-input.component';
import { ObjectInputComponent } from './components/atoms/inputs/object/object-input.component';
import { SelectInputComponent } from './components/atoms/inputs/select/select-input.component';
import { StringInputComponent } from './components/atoms/inputs/string/string-input.component';
import { ActionComponent } from './components/atoms/values/action/action.component';
import { BooleanComponent } from './components/atoms/values/boolean/boolean.component';
import { CurrencyComponent } from './components/atoms/values/currency/currency.component';
import { DateComponent } from './components/atoms/values/date/date.component';
import { EntityComponent } from './components/atoms/values/entity/entity.component';
import { FieldOkComponent as FieldOkDynamicComponent } from './components/atoms/values/field-ok/field-ok.component';
import { IconComponent } from './components/atoms/values/icon/icon.component';
import { ImageComponent } from './components/atoms/values/image/image.component';
import { ListComponent } from './components/atoms/values/list/list.component';
import { NumberComponent } from './components/atoms/values/number/number.component';
import { ObjectComponent } from './components/atoms/values/object/object.component';
import { SelectComponent } from './components/atoms/values/select/select.component';
import { StringComponent } from './components/atoms/values/string/string.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { DynamicObjectComponent } from './components/dynamic-object/dynamic-object.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicValueComponent } from './components/dynamic-value/dynamic-value.component';
import { FieldOkComponent } from './components/field-ok/field-ok.component';
import { DynamicInputFieldDirective } from './directives/dynamic-input-field.directive';
import { BooleanRulePipe } from './pipes/boolean-rule.pipe';
import { ClassRulePipe } from './pipes/class-rule.pipe';
import { FormatRulePipe } from './pipes/format-rule.pipe';
import { TitlePipe } from './pipes/title.pipe';
import { SelectedPipe } from './pipes/selected.pipe';
import { DurationComponent } from './components/atoms/values/duration/duration.component';
import { DurationInputComponent } from './components/atoms/inputs/duration/duration-input.component';
import { CombineValidationsPipe } from './pipes/combine-validations.pipe';
import { PropertyStylePipe } from './pipes/property-style.pipe';
import { PropertyClassPipe } from './pipes/property-class.pipe';
import { FieldOkInputComponent } from './components/atoms/inputs/field-ok/field-ok-input.component';
import { AddItemDialogComponent } from './components/atoms/inputs/list/add-item-dialog/add-item-dialog.component';
import { EditItemDialogComponent } from './components/atoms/inputs/list/edit-item-dialog/edit-item-dialog.component';
import { ResolveParametersPipe } from './pipes/resolve-parameters.pipe';
import { ResetInputComponent } from './components/atoms/inputs/reset/reset-input.component';
import { SubmitInputComponent } from './components/atoms/inputs/submit/submit-input.component';
import { DefinitionService } from './services/definition.service';
import { ScanDefinitionPipe } from './pipes/scan-definition.pipe';
import { GroupComponent } from './components/atoms/values/group/group.component';
import { GroupInputComponent } from './components/atoms/inputs/group/group-input.component';
import { MultiSelectInputComponent } from './components/atoms/inputs/multi-select/multi-select-input.component';
import { MultiSelectDialogComponent } from './components/atoms/inputs/multi-select/multi-select-dialog/multi-select-dialog.component';
import { FileInputComponent } from './components/atoms/inputs/file-input/file-input.component';
import { VirtualScrollItemDisplayPipe } from './pipes/virtual-scroll-item-display.pipe';
import * as DynamicActionStore from './dynamic-action.store';
import * as FieldOkComponentStore from './field-ok-component.store';
import * as DefinitionStore from './definition.store';
import {
  SOFTLINE_FEATURE_DEFINITIONS,
  SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
  SOFTLINE_FEATURE_FIELD_OK,
  TYPE_ACTION_COMPONENT,
  TYPE_ENTITY_INPUT_COMPONENT,
  TYPE_FIELD_OK_INPUT_COMPONENT,
  TYPE_ACTION_INPUT_COMPONENT,
  TYPE_LIST_COMPONENT,
  TYPE_LIST_INPUT_COMPONENT,
  TYPE_OBJECT_COMPONENT,
  TYPE_CONTAINER_COMPONENT, SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER, SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS
} from "./dynamic.shared";
import { DynamicValueFieldDirective } from './directives/dynamic-value-field.directive';
import { FieldOkComponentService } from './services/field-ok-component.service';
import { DynamicFilterComponent } from './components/dynamic-filter/dynamic-filter.component';
import { FilterDefinedValuesPipe } from './pipes/filter-defined-values.pipe';
import { ChangeFilterValueDialogComponent } from './components/dynamic-filter/change-filter-value-dialog/change-filter-value-dialog.component';
import { TextComponent } from './components/atoms/values/text/text.component';
import { ContainerComponent } from './components/atoms/values/container/container.component';
import { DynamicSkeletonComponent } from './components/dynamic-skeleton/dynamic-skeleton.component';
import { default as de } from '../i18n/de.json';
import { DrawComponent } from "./components/atoms/values/draw/draw.component";
import { DrawInputComponent } from "./components/atoms/inputs/draw/draw-input.component";
import { PermissionRuleResolver } from "./rules/permission.rule";

@NgModule({
  declarations: [
    ActionComponent,
    ActionInputComponent,
    BooleanComponent,
    BooleanInputComponent,
    CurrencyComponent,
    DateComponent,
    DateInputComponent,
    EntityComponent,
    EntityInputComponent,
    IconComponent,
    ImageComponent,
    ListComponent,
    ListInputComponent,
    NumberComponent,
    NumberInputComponent,
    ObjectComponent,
    ObjectInputComponent,
    SelectComponent,
    SelectInputComponent,
    StringComponent,
    StringInputComponent,
    FieldOkDynamicComponent,
    FieldOkInputComponent,
    GroupComponent,
    TextComponent,
    ContainerComponent,

    DynamicInputFieldDirective,
    DynamicValueFieldDirective,

    BooleanRulePipe,
    ClassRulePipe,
    FormatRulePipe,

    DynamicFormComponent,
    DynamicTableComponent,
    DynamicListComponent,
    DynamicObjectComponent,
    DynamicValueComponent,
    TitlePipe,
    SelectedPipe,
    DurationComponent,
    DurationInputComponent,
    CombineValidationsPipe,
    PropertyStylePipe,
    PropertyClassPipe,
    AddItemDialogComponent,
    EditItemDialogComponent,

    FieldOkComponent,

    ResolveParametersPipe,

    ResetInputComponent,

    SubmitInputComponent,

    ScanDefinitionPipe,

    GroupInputComponent,

    MultiSelectInputComponent,

    MultiSelectDialogComponent,

    FileInputComponent,

    DrawComponent,
    DrawInputComponent,

    VirtualScrollItemDisplayPipe,

    DynamicFilterComponent,
    FilterDefinedValuesPipe,
    ChangeFilterValueDialogComponent,
    DynamicSkeletonComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UiCoreModule,
    ScrollingModule,
    ScannerModule,
  ],
  providers: [DefinitionService, FormatRulePipe],
    exports: [
        DynamicFormComponent,
        DynamicTableComponent,
        DynamicListComponent,
        DynamicObjectComponent,
        DynamicValueComponent,
        DynamicFilterComponent,
        FieldOkComponent,
        IconComponent,
        PropertyClassPipe,
    ],
})
export class DynamicModule {
  static forRoot(): ModuleWithProviders<DynamicRootModule> {
    return {
      ngModule: DynamicRootModule,
      providers: [
        FieldOkComponentService,
        {
          provide: TYPE_FIELD_OK_INPUT_COMPONENT,
          useValue: FieldOkInputComponent,
        },
        {
          provide: TYPE_ENTITY_INPUT_COMPONENT,
          useValue: EntityInputComponent,
        },
        { provide: TYPE_LIST_INPUT_COMPONENT, useValue: ListInputComponent },
        { provide: TYPE_OBJECT_COMPONENT, useValue: ObjectComponent },
        { provide: TYPE_LIST_COMPONENT, useValue: ListComponent },
        { provide: TYPE_ACTION_COMPONENT, useValue: ActionComponent },
        {
          provide: TYPE_ACTION_INPUT_COMPONENT,
          useValue: ActionInputComponent,
        },
        { provide: TYPE_CONTAINER_COMPONENT, useValue: ContainerComponent },
        { provide: SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER, useClass: PermissionRuleResolver, multi: true},
        { provide: SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS, useValue: false}
      ],
    };
  }
}

@NgModule({
  imports: [
    DynamicModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DEFINITIONS,
      feature: DefinitionStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DYNAMIC_ACTIONS,
      feature: DynamicActionStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_FIELD_OK,
      feature: FieldOkComponentStore.feature,
    }),
  ],
  exports: [DynamicModule],
})
export class DynamicRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'dynamic',
      language: 'de',
      translations: de,
    });
  }
}
