import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS, SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE
} from "./warenverprobung.shared";
import {WarenverprobungStore} from './store/warenverprobung-store';
import {
  SOFTLINE_API_MDE_WARENVERPROBUNG_ARTIKEL,
  SOFTLINE_API_MDE_WARENVERPROBUNG_BEWEGUNGEN
} from './warenverprobung.api';
import {WarenverprobungBeweStore} from './store/warenverprobung-bewe.store';
import {WarenverprobungService} from './services/warenverprobung.service';
import {WarenverprobungArtikelDetailStore} from './store/warenverprobung-artikel-detail.store';
import {WarenverprobungResolver} from './resolver/warenverprobung-resolver.service';
import {WarenverprobungBelegeStore} from './store/warenverprobung-belege.store';

export const warenverprobungProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeWarenverprobung',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Warenverprobung',
          icon: 'fas fa-vials',
          route: '/mde-warenverprobung',
          permission: 'WARENVERPROBUNG',
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE,
        WarenverprobungBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
      feature: WarenverprobungStore.feature,
      providers: [
        {
          provide: WarenverprobungService,
          useClass: WarenverprobungService
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE,
      feature: WarenverprobungBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_WARENVERPROBUNG_BEWEGUNGEN
        )
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE,
      feature: WarenverprobungBelegeStore.feature,
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS,
      feature: WarenverprobungArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_WARENVERPROBUNG_ARTIKEL,
              'detail'
            );
          }
        }
      ]
    })
  ),
  WarenverprobungResolver,
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG, fields: ['art'] },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE, fields: ['entities', 'ids'] },
    multi: true
  },
]
