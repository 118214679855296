import {Component, Inject, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Store} from "@softline/core";
import {EditContextDialogComponent,} from "../../../dialogs/edit-context-dialog/edit-context-dialog.component";

import {switchMap} from "rxjs/operators";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {RefreshService} from "@softline/application";
import {
  AccountContextConfiguration,
  SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
  SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
  UtilitiesAccountStore
} from "../../../../utilities.shared";

@Component({
  selector: 'soft-account-context-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './account-context-widget.component.html',
  styleUrls: ['./account-context-widget.component.scss'],
})
export class AccountContextWidgetComponent implements OnInit {

  userAccounts$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
    UtilitiesAccountStore.getters.all
  );

  context$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  );

  activeUserAccount$ = this.context$
    .pipe(
      switchMap(context => this.store.observe(
        SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
        UtilitiesAccountStore.getters.entity,
        (context as any)?.accountid)
      )
    );

  constructor(
    private store: Store,
    private refreshService: RefreshService,
    @Inject(SOFTLINE_CONFIG_CONTEXT_ACCOUNT) readonly accountContextConfig: AccountContextConfiguration
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_ACCOUNT,
      UtilitiesAccountStore.actions.loadMany,
      { clear: true }
    );
  }

  async onChangeAccount(): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      component: EditContextDialogComponent,
      dismiss: {
        backdrop: true,
        escape: true,
        button: true
      }
    });
    if (result === 'DISMISSED')
      return;

    await this.refreshService.refresh()
  }
}
