import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { I18nModule, UiCoreComponentsModule } from "@softline/ui-core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SettingsStore, SOFTLINE_FEATURE_SETTINGS } from "@softline/application";
import { IntegratedScannerSettings } from "../types/integrated-scanner-settings";
import { SOFTLINE_SETTINGS_INTEGRATED_SCANNER } from "../integrated-scanner.shared";
import { Subscription } from "rxjs";
import { Store } from "@softline/core";

@Component({
  selector: 'soft-external-scanner',
  standalone: true,
  imports: [CommonModule, I18nModule, ReactiveFormsModule, UiCoreComponentsModule],
  templateUrl: './integrated-scanner-settings.component.html',
  styleUrls: ['./integrated-scanner-settings.component.scss']
})
export class IntegratedScannerSettingsComponent implements OnInit, OnDestroy {

  private storeSubscription?: Subscription;
  private formSubscription?: Subscription;

  form = new FormGroup({
    scanEverywhere: new FormControl(false)
  })

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.storeSubscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<IntegratedScannerSettings>(),
        SOFTLINE_SETTINGS_INTEGRATED_SCANNER
      )
      .subscribe((o) => {
        this.form.patchValue(o ?? {}, { emitEvent: false });
      });

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      this.store.commit(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.mutations.setKey(),
        {key: SOFTLINE_SETTINGS_INTEGRATED_SCANNER, values: this.form.value as any}
        )
      this.store.dispatch(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.actions.save
      )
    })
  }

  ngOnDestroy() {
    if (this.storeSubscription && !this.storeSubscription.closed)
      this.storeSubscription.unsubscribe();
    this.storeSubscription = undefined;
    if (this.formSubscription && !this.formSubscription.closed)
      this.formSubscription.unsubscribe();
    this.formSubscription = undefined;
  }

}
