import { Directive, forwardRef, Input } from '@angular/core';
import { SelectOptionBase } from './select-option-base';

@Directive({
  selector: 'soft-select-option-header',
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionHeaderDirective),
    },
  ],
})
export class SelectOptionHeaderDirective extends SelectOptionBase {
  @Input() id?: string;
  @Input() text?: string;

  constructor() {
    super();
  }
}
