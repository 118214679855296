import {Store} from "@softline/core";
import {ENVIRONMENT_INITIALIZER, inject} from "@angular/core";
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from "@softline/ui-core";
import {default as de} from "../i18n/de.json";
import {artikelProviders} from "./artikel/artikel.providers";
import {einheiteneingabeProviders} from "./einheiteneingabe/einheiteneingabe.providers";

export const wwsCoreProviders = [
  ...artikelProviders,
  ...einheiteneingabeProviders,
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'wws-core',
        language: 'de',
        translations: de
      });
    },
    multi: true
  }
]
