<cdk-accordion [multi]="multi">
  <cdk-accordion-item 
    *ngFor="let item of $any((accordionItems || [])); let index = index"
    #accordionItem="cdkAccordionItem"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <ng-container *ngTemplateOutlet="item.template; context: { expanded: accordionItem.expanded, accordionItem: accordionItem }"></ng-container>
  </cdk-accordion-item>
</cdk-accordion>
