import { Pipe, PipeTransform } from '@angular/core';
import { equals } from '@softline/core';

@Pipe({
  name: 'isSelected',
  pure: false,
})
export class SelectedPipe implements PipeTransform {
  transform(value: any, selected: any | any[]): boolean {
    if (Array.isArray(selected))
      return selected.findIndex((o) => equals(o, value)) !== -1;
    return equals(selected, value);
  }
}
