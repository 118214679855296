import {ArchiveFile, Command, UploadFileCommand} from '@softline/application';
import {Store} from '@softline/core';
import {Router} from '@angular/router';
import {Injector} from '@angular/core';
import {Auftrag} from '../../data/auftrag';
import {Arbeitsbericht} from '../../data/stempel';
import {AddTaskItemCommand} from '../../commands/add-task-item.command';
// import {StartActivityCommand} from '../../commands/start-activity.command';
import {EndActivityCommand} from '../../commands/end-activity.command';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN} from '../../auftrag.permissions';
import {ManualTimeReportCommand} from '../../commands/manual-time-report.command';
import {of} from 'rxjs';
// import {SignatureCommand} from '../../commands/signature.command';
// import {CloseTaskCommand} from '../../commands/close-task.command';
import {EditHeaderTextCommand} from '../../commands/edit-header.command';
import {SOFTLINE_API_WERKSTAETTENAUFTRAG_ARCHIVE_KEY_RESOURCE_PATH} from '../../auftrag.api';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../../auftrag.shared';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store'
import {StartActivityCommand} from '../../commands/start-activity.command';
import {SignatureCommand} from '../../commands/signature.command';
import {CloseTaskCommand} from '../../commands/close-task.command';

export function createAuftragCommands({
  store,
  router,
  injector,
  getTask,
  permission,
  timeReport
}: {
  store: Store,
  router: Router,
  injector: Injector,
  getTask: () => Auftrag,
  permission: string[],
  timeReport: Arbeitsbericht | undefined
}): Command[] {
  const commands: Command[] = [
    new AddTaskItemCommand(router, getTask),
    new StartActivityCommand(store, getTask),
    new EndActivityCommand(store, timeReport, getTask)
  ];

  if (permission.indexOf(SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN) > -1) {
    commands.push(new ManualTimeReportCommand(router, getTask));
  }

  return [
    ...commands,
    new UploadFileCommand(
      store,
      {
        canExecute: of(true),
        sources: ['camera', 'cameraRoll'],
        archiveKey: getTask()?.archiveKey ?? {
          path: SOFTLINE_API_WERKSTAETTENAUFTRAG_ARCHIVE_KEY_RESOURCE_PATH,
          pathParams: {id: getTask()?.id}
        },
        onSuccess: async (files: ArchiveFile[]) => {
          store.commit(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
            WerkstattAuftraegeStore.mutations.patch,
             { id: getTask().id, changes: { archiveKey: (files[0] as any).result?.archiveKey } }
          );

          await store.dispatch(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
            WerkstattAuftraegeStore.actions.load,
            { id: getTask().id}
          )
        }
      }
    ),
    new SignatureCommand(store, getTask),
    new CloseTaskCommand(store, getTask, router),
    new EditHeaderTextCommand(store, getTask)
  ];
}
