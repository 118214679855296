import { ConnectionHttpService, Dictionary } from '@softline/core';
import { FieldOkParameters } from '../data/field-ok';
import { Observable } from 'rxjs';
import { Definition } from '../data/definitions';
import { map } from 'rxjs/operators';
import { convertDefinition } from '../converter/definition.converter';
import { convertData } from '../converter/data.converter';
import { Injectable } from '@angular/core';
import {
  SOFTLINE_API_FIELD_OK_QUERY,
  SOFTLINE_API_FIELD_OK_QUERY_DEFINITION,
  SOFTLINE_API_FIELD_OK_RESULT,
  SOFTLINE_API_FIELD_OK_VALIDATE,
} from '../dynamic.api';

export interface FieldOkResponse {
  definition: any;
  data: Dictionary<unknown>;
  attributeType: 'Bean' | 'Abfrage' | 'Value';
}

export type FieldOkValidationResult =
  | FieldOkQueryResult
  | FieldOkObjectResult
  | FieldOkValueResult;

export interface FieldOkQueryResult<T extends object = object> {
  type: 'query';
  data: T[];
  definition: Definition | null;
}

export interface FieldOkObjectResult<T extends object = object> {
  type: 'object';
  data: T | null;
  definition: Definition | null;
}

export interface FieldOkValueResult {
  type: 'value';
  data: string | number;
}

@Injectable()
export class FieldOkComponentService {
  constructor(protected service: ConnectionHttpService) {}

  validate(
    name: string,
    params: FieldOkParameters
  ): Observable<FieldOkValidationResult> {
    const location = {
      path: SOFTLINE_API_FIELD_OK_VALIDATE,
      pathParams: { fieldOk: name },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(location, params)
      .pipe(map((o) => this.convertValidationResponse(o)));
  }

  query(
    name: string,
    params: FieldOkParameters
  ): Observable<FieldOkQueryResult> {
    const location = {
      path: SOFTLINE_API_FIELD_OK_QUERY,
      pathParams: { fieldOk: name },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(location, params)
      .pipe(map((o) => this.convertQueryResponse(o)));
  }

  loadResult(
    name: string,
    params: FieldOkParameters,
    id: number | string
  ): Observable<FieldOkObjectResult> {
    const location = {
      path: SOFTLINE_API_FIELD_OK_RESULT,
      pathParams: { fieldOk: name, key: encodeURIComponent(id) },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(location, params)
      .pipe(map((o) => this.convertObjectResponse(o)));
  }

  loadQueryDefinition(
    name: string,
    params: FieldOkParameters
  ): Observable<Definition> {
    const location = {
      path: SOFTLINE_API_FIELD_OK_QUERY_DEFINITION,
      pathParams: { fieldOk: name },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(location, params)
      .pipe(map((o) => convertDefinition(o)));
  }

  private convertValidationResponse(
    response: FieldOkResponse
  ): FieldOkValidationResult {
    switch (response.attributeType) {
      case 'Value':
        return this.convertValueResponse(response);
      case 'Abfrage':
        return this.convertQueryResponse(response);
      case 'Bean':
        return this.convertObjectResponse(response);
      default:
        throw new Error(
          `FieldOkComponentService: Unknown result type ''${response.attributeType}`
        );
    }
  }

  private convertQueryResponse(result: FieldOkResponse): FieldOkQueryResult {
    const returnValue: FieldOkQueryResult = {
      type: 'query',
      data: [],
      definition: null,
    };
    if (result.data) returnValue.data = convertData(result.data);
    if (result.definition)
      returnValue.definition = convertDefinition(result.definition);
    return returnValue;
  }

  private convertObjectResponse(result: FieldOkResponse): FieldOkObjectResult {
    const returnValue: FieldOkObjectResult = {
      type: 'object',
      data: null,
      definition: null,
    };
    if (result.data) returnValue.data = result.data;
    if (result.definition)
      returnValue.definition = convertDefinition(result.definition);
    return returnValue;
  }

  private convertValueResponse(result: FieldOkResponse): FieldOkValueResult {
    const returnValue: FieldOkValueResult = {
      type: 'value',
      data: '',
    };
    if (result.data) returnValue.data = result.data as unknown as string;
    return returnValue;
  }
}
