<h4 class="font-semibold text-xl mt-2 font-semibold">{{ '#WORKSHOP.DIALOGS.START_ACTIVITY.TITLE' | translate }}</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3">
  <soft-field-ok
    class="mt-3"
    formControlName="activity"
    [name]="'wtaetigkeit'"
    [autoLoad]="true"
    [template]="activityTemplate"
    [views]="taetigkeitenViews"
    [placeholder]="'#WORKSHOP.DIALOGS.START_ACTIVITY.ACTIVITY_LABEL' | translate"
    [resultMapping]="{ 'bezeichnung': 'title', 'id': 'id', 'taetigkeit': 'name' }">
  </soft-field-ok>
  <ng-template let-entity="entity" #activityTemplate>
    <span>{{ entity.id }} - {{ entity.title }}</span>
  </ng-template>

  <soft-multiline-input
    [lines]="3"
    class="mt-3"
    formControlName="description"
    [placeholder]="'#WORKSHOP.DIALOGS.START_ACTIVITY.DESCRIPTION_LABEL' | translate">
  </soft-multiline-input>

  <soft-message-bar-container [name]="'START_ACTIVITY_MSG_BAR'">
    <soft-message-bar></soft-message-bar>
  </soft-message-bar-container>

  <div class="c row no-wrap w-full mt-3">
    <button class="soft-button secondary w-full text-center h-16 fill-force mr-2" (click)="close(null)">{{ '#WORKSHOP.DIALOGS.ABORT' | translate }}</button>
    <button type="submit" class="soft-button text-center h-16 primary fill-force text-center ml-2 w-full" [disabled]="form.invalid">
      {{ '#WORKSHOP.DIALOGS.START_ACTIVITY.START' | translate }}
    </button>
  </div>
</form>
