<div class="c row center">
  <h4 class="text-lg font-bold">
    {{ '#PASSWORD.COMPONENTS.USER_FORM.TITLE' | translate }}
  </h4>
</div>
<div class="soft-title light mt-4">
  {{ '#PASSWORD.COMPONENTS.USER_FORM.STEP1' | translate }}
</div>
<hr class="soft-separator" />

<form [formGroup]="form" (ngSubmit)="submit(form.value)">
  <div class="c column mt-4 required">
    <label for="password-user-form-username" class="soft-label top required">
      {{ '#PASSWORD.COMPONENTS.USER_FORM.USERNAME' | translate }}
    </label>
    <soft-input
      formControlName="username"
      id="password-user-form-username"
      autocomplete="username"
    ></soft-input>
    <soft-validation-container
      *ngIf="form.controls['username'].touched"
      [validation]="form | validationMessage: 'username'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="c column mt-4">
    <label for="password-user-form-email" class="soft-label top required">
      {{ '#PASSWORD.COMPONENTS.USER_FORM.EMAIL' | translate }}
    </label>
    <soft-input
      formControlName="email"
      id="password-user-form-email"
      autocomplete="email"
    ></soft-input>
    <soft-validation-container
      *ngIf="form.controls['email'].touched"
      [validation]="form | validationMessage: 'email'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="soft-text xs color light mt-2">
    {{ '#PASSWORD.COMPONENTS.USER_FORM.INFO' | translate }}
  </div>
  <div class="c center mt-3">
    <input
      type="submit"
      value="{{ '#PASSWORD.COMPONENTS.USER_FORM.SUBMIT' | translate }}"
    />
  </div>
</form>
