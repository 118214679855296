import { Component } from '@angular/core';

@Component({
  template: `
    <soft-combo-box [value]="'default'">
      <soft-combo-box-option [value]="'Test 1'"></soft-combo-box-option>
      <soft-combo-box-option [value]="'Test 2'"></soft-combo-box-option>
      <soft-combo-box-option [value]="'Test 3'"></soft-combo-box-option>
    </soft-combo-box>
  `,
})
export class TestWrapperComponent {}
