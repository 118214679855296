<h4 class="font-semibold text-xl mt-2 mb-1">{{ '#WORKSHOP.DIALOGS.CLOSE_TASK.TITLE' | translate }}</h4>
<p class="soft-text-s color-light">
  {{ '#WORKSHOP.DIALOGS.CLOSE_TASK.INSTRUCTIONS' | translate }}
</p>

<div class="select-list">
  <div class="select-list-item"
       (click)="select('approval')"
       [ngClass]="[selectedMethod === 'approval' ? 'selected' : '']">
    {{ '#WORKSHOP.DIALOGS.CLOSE_TASK.APPROVAL' | translate }}
  </div>
  <div class="select-list-item"
       (click)="select('deliveryNote')"
       [ngClass]="[selectedMethod === 'deliveryNote' ? 'selected' : '']">
    {{ '#WORKSHOP.DIALOGS.CLOSE_TASK.DELIVERY_NOTE' | translate }}
  </div>
  <div class="select-list-item"
       (click)="select('invoice')"
       [ngClass]="[selectedMethod === 'invoice' ? 'selected' : '']">
    {{ '#WORKSHOP.DIALOGS.CLOSE_TASK.INVOICE' | translate }}
  </div>
</div>

<soft-message-bar-container [name]="'CLOSE_TASK_MSG_BAR'">
  <soft-message-bar></soft-message-bar>
</soft-message-bar-container>


<div class="actions c row no-wrap mt-4 w-full">
  <button class="soft-button h-16 w-full text-center secondary fill-force mr-2" (click)="close('cancel')"> {{ '#WORKSHOP.DIALOGS.ABORT' | translate }}</button>
  <button class="soft-button h-16 w-full text-center primary fill-force text-center ml-2"
          (click)="continue()"
          [disabled]="(isSaving$ | async) || !canContinue">
    <span>
      <i *ngIf="isSaving$ | async" class="fas fa-spinner fa-spin"></i>
      {{ '#WORKSHOP.DIALOGS.SUBMIT_CONTINUE' | translate }}
    </span>
  </button>
</div>
