import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Auftrag} from '../../data/auftrag';

@Component({
  selector: 'soft-auftrag-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './auftrag-card.component.html',
  styleUrls: ['./auftrag-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuftragCardComponent {
  @Input() auftrag?: Auftrag
}
