import { Component, Input, OnInit } from '@angular/core';
import { Definition } from '../../data/definitions';

@Component({
  selector: 'soft-dynamic-value',
  templateUrl: './dynamic-value.component.html',
  styleUrls: ['./dynamic-value.component.scss'],
})
export class DynamicValueComponent implements OnInit {
  @Input() definition?: any;
  @Input() value?: any;

  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
