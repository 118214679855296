import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_RETOUREN,
  SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_RETOUREN_BEWE, SOFTLINE_PERMISSION_MDE_RETOUREN
} from "./retouren.shared";
import {RetourenStore} from './store/retouren.store';
import {SOFTLINE_API_MDE_RETOUREN_ARTIKEL, SOFTLINE_API_MDE_RETOUREN_BEWEGUNGEN} from './retouren.api';
import {RetourenBeweStore} from './store/retouren-bewe.store';
import {RetourenService} from './services/retouren.service';
import {RetourenArtikelDetailStore} from './store/retouren-artikel-detail.store';
import {RetourenResolver} from './resolver/retouren.resolver';

export const retourenProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeRetouren',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Retouren',
          icon: 'fas fa-backward',
          route: '/mde-retouren',
          permission: SOFTLINE_PERMISSION_MDE_RETOUREN,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_RETOUREN_BEWE,
        RetourenBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_RETOUREN,
      feature: RetourenStore.feature,
      providers: [{
        provide: RetourenService,
        useClass: RetourenService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_RETOUREN_BEWE,
      feature: RetourenBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_RETOUREN_BEWEGUNGEN
        )
      }]
    })
  ),
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS,
      feature: RetourenArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_RETOUREN_ARTIKEL,
              'detail'
            );
          }
        }
      ]
    })
  ),
  RetourenResolver,
]
