export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_RESOURCE_PATH = '/v1/garage/order/entities';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ARCHIVE_KEY_RESOURCE_PATH = '/v1/garage/order/entities/{{id}}/archivekey';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_TEMPLATE_RESOURCE_PATH = '/v1/garage/order/default';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_RESOURCE_PATH = '/v1/garage/order/items/entities';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH = '/v1/garage/timereport/entities';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_FILE_RESOURCE_PATH = 'v2/file';

export const SOFTLINE_API_WERKSTAETTENAUFTRAG_SIGN_TASK_RESOURCE_PATH = '/v1/garage/order';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TIME_REPORT_RESOURCE_PATH = '/v1/garage/timereport';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_TEMPLATE_RESOURCE_PATH = '/v1/garage/order/item/default';

export const SOFTLINE_API_WERKSTAETTENAUFTRAG_ARCHIVE_KEY_RESOURCE_PATH = '/v1/garage/order/entities/{{id}}/archivekey';

export const SOFTLINE_API_WERKSTAETTENAUFTRAG_VALIDATE_SCAN_RESOURCE_PATH = '/v1/items/scan/{{labelType}}/{{data}}';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_QUERY_RESOURCE_PATH = '/v1/bean/query/artstamm';
export const SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_RESULT_RESOURCE_PATH = '/v1/bean/abfrageResult/artstamm/{{id}}';

export const SOFTLINE_API_WERKSTAETTENAUFTRAG_MULTIPLE_ACTIVE_TIME_REPORTS_ERROR = 'MULTIPLE_ACTIVE_TIME_REPORTS_ERROR';
