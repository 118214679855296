<div
  *ngIf="definition && form"
  class="soft-list-item-action"
  [formGroup]="form"
>
  <ng-container [ngSwitch]="definition.control">
    <input
      *ngSwitchCase="'button'"
      type="button"
      class="soft-button secondary"
      (click)="onClick()"
    />
    <soft-dynamic-value
      [value]="form.value"
      [definition]="definition"
    ></soft-dynamic-value>
  </ng-container>
  <a *ngSwitchDefault (click)="onClick()" class="soft-link">
    <soft-dynamic-value
      [value]="form.value"
      [definition]="definition"
    ></soft-dynamic-value>
  </a>
</div>
